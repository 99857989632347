import React, { useRef, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Collapse, Button, Hidden, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from 'src/kiska/components/contexts/ThemeProviderContext';
import { GeneratePdfButton } from 'src/kiska/components/PdfGenerator';
import { Print as PrintIcon, Share as ShareIcon } from '@material-ui/icons';
import DownloadIcon from 'mdi-material-ui/Download';
import EmailIcon from 'mdi-material-ui/Email';
import ViewIcon from 'mdi-material-ui/Eye';
import HideIcon from 'mdi-material-ui/EyeOff';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';

const isSsr = typeof window === 'undefined';

const wrapperUseStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    backgroundRepeat: 'repeat',
    color: theme.palette.text.primary,
    '@media only screen': {
      padding: theme.spacing(4),
    },
  },
}));

const textToBackgroundImage = (text) => {
  const svgstring = `
    <svg id="diagtext" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%">
      <style type="text/css">
        text { 
          font-size: 100px;
          letter-spacing: 0px;
          fill: #EEE; 
          font-weight: 100;
          font-family: 'Exo 2', sans-serif; 
        }
      </style>
      <defs>
        <pattern id="text" patternUnits="userSpaceOnUse" width="2000" height="400">
          <text y="150" id="name">
            ${text}
          </text>
        </pattern>
        <pattern id="combo" xlink:href="#text" patternTransform="rotate(-45) translate(-600 0) scale(.6 1)">
          <use xlink:href="#name" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#combo)" />
    </svg>
  `;

  const image = `url('data:image/svg+xml;base64,${window.btoa(svgstring)}')`;
  return image;
};

const ReportWrapper = forwardRef((props, forwardedRef) => {
  const { children, watermarkText, ...rest } = props;
  const classes = wrapperUseStyles(props);

  const backgroundImage = watermarkText && !isSsr ? textToBackgroundImage(watermarkText) : undefined;

  return (
    <div className={classes.root} {...rest} ref={forwardedRef} style={{ backgroundImage }}>
      {children}
    </div>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  table: {
    '& td': {
      borderBottom: 'none !important',
    },
  },
  titleCell: {
    cursor: 'pointer',
  },
  buttonsCell: {
    textAlign: 'right',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  button: {
    padding: theme.spacing(0.5, 1),
    minWidth: 0,
  },
  previewRow: {

  },
}));

const ReportRow = (props) => {
  const [showPreview, setShowPreview] = useState(false);
  const { report, job } = props;
  const classes = useStyles(props);
  const ref = useRef();

  const pdfProps = {
    targetRef: ref,
    name: `${job.title} - ${report.title}.pdf`,
    size: 'small',
    className: classes.button,
  };

  const emailData = {
    subject: `${report.title} Report`,
    body: `Hello,\n\nYou have been sent a "${report.title}" Report. Please find the attached PDF file.`,
  };

  const handlePreviewClick = () => {
    setShowPreview(!showPreview);
  };

  return (
    <>
      <TableRow hover>
        <TableCell className={classes.titleCell} onClick={handlePreviewClick}>
          <Typography variant="h6" color="secondary">{report.title}</Typography>
        </TableCell>

        <TableCell className={classes.buttonsCell}>
          <Button size="small" variant="contained" className={classes.button} onClick={handlePreviewClick}>
            {showPreview ? <HideIcon /> : <ViewIcon />}
            <Hidden smDown><span>{showPreview ? 'Hide' : 'Preview'}</span></Hidden>
          </Button>

          {/* <GeneratePdfButton {...pdfProps} mode="view">
            <ViewIcon />
            <Hidden smDown><span>View</span></Hidden>
          </GeneratePdfButton> */}

          <GeneratePdfButton {...pdfProps} mode="download">
            <DownloadIcon />
            <Hidden smDown><span>Download</span></Hidden>
          </GeneratePdfButton>

          <GeneratePdfButton {...pdfProps} mode="print">
            <PrintIcon />
            <Hidden smDown><span>Print</span></Hidden>
          </GeneratePdfButton>

          <GeneratePdfButton {...pdfProps} mode="email" emailData={emailData}>
            <EmailIcon />
            <Hidden smDown><span>Email</span></Hidden>
          </GeneratePdfButton>
        </TableCell>
      </TableRow>

      <TableRow className={classes.previewRow}>
        <TableCell colSpan={2}>
          <Collapse in={showPreview}>
            <ThemeProvider key={report.id} initialActiveThemeName="print">
              <ReportWrapper ref={ref} watermarkText={report.watermarkText}>
                {report.preview}
              </ReportWrapper>
            </ThemeProvider>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const StaticReportGenerator = (props) => {
  const { reports, job } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Table size="small" className={classes.table}>
        <TableBody>
          {reports.map((report) => <ReportRow key={report.id} report={report} job={job} />)}
        </TableBody>
      </Table>
    </div>
  );
};

StaticReportGenerator.propTypes = {
};
StaticReportGenerator.defaultProps = {
};

export { StaticReportGenerator };
