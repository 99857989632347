/* eslint-disable react/prop-types */
import React from 'react';
import { fragments } from './fragments';

export default {
  types: {
    work_entry: {
      renderers: {
        inline: ({ node }) => <>{node.user.displayName} working on {node.job.title}</>,
      },

      hooks: require('src/hooks/work_entry'),

      selections: {
        default: `
          id
          start startTime end endTime duration notes
          task {id label}
          job {
            ${fragments.job.basic}
            tasks(order_by: [{task: {label: asc}}]) { id task {id label}}
            forms { id job{id} form{id} }
            files(order_by: {file: {updatedAt: desc}}) { id file {
              id url mimeType size name
            }}
          }
          user {
            id displayName email
            avatar {id url mimeType name }
            role { id label }
            payRates {
              id pay_rate { 
                id label price cost tasks(order_by: [{task: {label: asc}}]) { 
                  id task { id label description }
                }
              }
            }
          }
        `,
        openEntries: `
          id
          start startTime end endTime duration notes
          task {id label}
          job {
            ${fragments.job.basic}
            tasks(order_by: [{task: {label: asc}}]) { id task {id label}}
            forms { id job{id} form{id} }
            files(order_by: {file: {updatedAt: desc}}) { id file {
              id url mimeType size name
            }}
            expenses_aggregate{aggregate{count}}
            change_orders_aggregate: forms_aggregate(where: {form: {name:{_eq: "ChangeOrderForm"}}}){aggregate{count}}
            resources{id resource{id title description link createdAt updatedAt userId expires files}}
            resources_aggregate{aggregate{count}}
          }
          user {
            id displayName email
            avatar {id url mimeType name }
            role { id label }
            payRates {
              id pay_rate { 
                id label price cost tasks(order_by: [{task: {label: asc}}]) { 
                  id task { id label description }
                }
              }
            }
          }
        `,
      },

      fields: {
        user: {
          type: 'user',
          label: 'Worker',
          validation: {
            required: true,
          },
        },

        job: {
          type: 'job',
          validation: {
            required: true,
          },
        },

        start: {
          type: 'Timestamp',
          validation: {
            required: true,
          },
        },

        startTime: {
          type: 'Time',
          clientOnly: true,
        },

        end: {
          type: 'Timestamp',
          validation: {
            required: false,
          },
          defaultValue: null,
        },

        endTime: {
          type: 'Time',
          defaultValue: null,
          clientOnly: true,
        },

        duration: {
          type: 'Int',
          label: 'Hours',
          clientOnly: true,
          renderers: {
            inline: ({ value }) => (value / 60 / 60).toFixed(1),
          },
        },

        notes: {
          type: 'String',
        },

        task: {
          type: 'task',
          validation: {
            required: true,
          },
        },

        payRate: {
          type: 'Json',
          validation: { required: false },
        },

      },
    },
  },
};
