import React, { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FileField, DateField, NodeField, BooleanField, SelectField } from 'src/kiska/components/ViewOrUpdateFields';
import _ from 'lodash';
import { useLocalNode } from 'src/kiska/components/LocalNodeContext';
import { Grid } from 'src/kiska/components/Grid';
import { FormSection, GridLineBreak } from 'src/components/forms';

import { forms } from 'src/components/modules/form/forms';
import { SimpleTable } from 'src/components/dashboards/settings/SimpleTable';
import { Hideable } from 'src/kiska/components/Hideable';
import { format, parseISO } from 'date-fns';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { useViewOrUpdate } from 'src/kiska/components/useViewOrUpdate';
import { useNode, useNodes } from 'src/kiska/hooks/useNode';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { StaticReportGenerator } from 'src/components/modules/job/StaticReportGenerator';
import { FormViewDialog } from '../../form/FormViewDialog';
import { AsbestosRiskAssessmentReport } from '../static-reports/AsbestosRiskAssessmentReport';
import { LimitedHazardousMaterialsSurvey } from '../static-reports/LimitedHazardousMaterialsSurvey';
import { usePrevDailySafetyFormValues } from '../../form/forms/usePrevDailySafetyFormValues';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  content: {
    padding: theme.spacing(0, 0, 0, 0),
  },
}));

const stdWidths = { xs: 12, sm: 12, md: 6, lg: 3 };

const optionValueToLabel = (options, value) => {
  const option = options.find((o) => o.value === value);
  if (!option) return '';
  return option.label;
};

const buildReports = (job, schema) => {
  const { getOption } = schema;
  const data = _.get(job, 'specs.wcbReports', {});

  let materialType = _.get(job, 'specs.asbestos.materialType', []);
  if (!Array.isArray(materialType)) materialType = [materialType];
  materialType = materialType.filter((m) => !!m).map((m) => getOption('job', 'specs.asbestos.materialType', m).primary).join(', ');

  const vars = {
    projectLocation: data.projectLocation,
    assessmentDate: data.assessmentDate ? format(typeof data.assessmentDate === 'string' ? parseISO(data.assessmentDate) : data.assessmentDate, `MMM d, yyyy`) : '',
    hiredBy: data.hiredBy,
    asbestosRisk: getOption('job', 'specs.asbestos.risk', job).primary,
    asbestosMaterial: materialType,
    removalArea: data.removalArea,
    removalProcedure: data.removalProcedure,
    samplesTakenBy: data.samplesTakenBy,
    sampleResults: data.sampleResults,
    materialCondition: data.materialCondition,
    friability: data.friability,
    riskToPersonsOutside: data.riskToPersonsOutside,
    ahearaBuildingInspector: data.ahearaBuildingInspector,
    riskComplianceMeasures: data.riskComplianceMeasures,
    coverImage: data.coverImage,
    assessmentBy: data.assessmentBy,
    labName: data.labName,
    sampleId: data.sampleId,
    reasonForSurvey: data.reasonForSurvey,
    analyticalResultsFiles: data.analyticalResultsFiles || [],
  };

  const reports = [
    {
      id: 'ara',
      title: 'Risk Assessment',
      preview: <AsbestosRiskAssessmentReport vars={vars} />,
      watermarkText: `ASSURED ASBESTOS ABATEMENT`,
    },
    {
      id: 'lhms',
      title: 'Haz Mat Survey',
      preview: <LimitedHazardousMaterialsSurvey vars={vars} />,
      watermarkText: `ASSURED ASBESTOS ABATEMENT`,
    },
  ];

  return reports;
};

const AsbestosForm = forwardRef((props, forwardedRef) => {
  const classes = useStyles(props);
  const { node } = useLocalNode();
  const { printMode } = usePrint();
  const { update } = useViewOrUpdate();
  const { ready: dailySafetyInitialValuesReady, initialValues: dailySafetyInitialValues } = usePrevDailySafetyFormValues({ job: node });
  const schema = useSchema();

  if (!dailySafetyInitialValuesReady) return null;

  const asbestosRiskLevel = _.get(node, 'specs.asbestos.risk');

  const dailySafetyColumns = [
    {
      label: 'Date',
      fieldName: 'createdAt',
      Renderer: ({ value }) => format(parseISO(value), `EEE, MMM d, yyyy`),
    },
    // {
    //   label: 'Signatures',
    //   fieldName: 'signatures_aggregate',
    //   Renderer: ({ value }) => value.aggregate.count,
    // },
  ];

  const reports = buildReports(node, schema);

  return (
    <Hideable>

      <FormSection title="Asbestos">
        <Grid item {...stdWidths}>
          <SelectField name="specs.asbestos.risk" label="Risk Level" />
        </Grid>
        <Grid item {...stdWidths}>
          <SelectField
            name="specs.asbestos.materialType"
            label="Type of Material"
            filterOptions={(option) => asbestosRiskLevel === option.riskLevel}
            multiple
          />
        </Grid>
      </FormSection>

      <Hideable hidden={printMode}>
        <FormSection title="WCB Forms" initiallyExpanded={false}>
          <Grid item xs={12}>
            <TextField name="specs.wcbReports.projectLocation" />
          </Grid>

          <Grid item xs={12} md={6}>
            <DateField name="specs.wcbReports.assessmentDate" />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField name="specs.wcbReports.assessmentBy" label="Conducted By" />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField name="specs.wcbReports.hiredBy" />
          </Grid>

          <Grid item xs={12}>
            <TextField name="specs.wcbReports.removalArea" />
          </Grid>
          <Grid item xs={12}>
            <TextField name="specs.wcbReports.reasonForSurvey" />
          </Grid>

          <Grid item xs={12}>
            <TextField name="specs.wcbReports.removalProcedure" />
          </Grid>

          <Grid item xs={12}>
            <TextField name="specs.wcbReports.riskComplianceMeasures" />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField name="specs.wcbReports.samplesTakenBy" />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField name="specs.wcbReports.sampleResults" />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField name="specs.wcbReports.sampleId" />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField name="specs.wcbReports.labName" />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField name="specs.wcbReports.materialCondition" />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField name="specs.wcbReports.friability" />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField name="specs.wcbReports.riskToPersonsOutside" />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField name="specs.wcbReports.ahearaBuildingInspector" label="AHEARA Building Inspector #" />
          </Grid>
          <GridLineBreak />

          <Grid item xs={12} sm={6} md={4}>
            <FileField name="specs.wcbReports.coverImage" />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <FileField
              name="specs.wcbReports.analyticalResultsFiles"
              label="Analytical Results Images"
              allowedFileTypes={['image/*']}
            />
          </Grid>

          <Grid item xs={12}>
            <StaticReportGenerator reports={reports} job={node} />
          </Grid>
        </FormSection>
      </Hideable>
    </Hideable>
  );
});

AsbestosForm.propTypes = {
  component: PropTypes.elementType,
};
AsbestosForm.defaultProps = {
  component: 'div',
};
AsbestosForm.displayName = 'AsbestosForm';

export { AsbestosForm };
