import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import logo from './aaa-black.png';

const useStyles = makeStyles((theme) => ({
  root: {
    breakInside: 'avoid',
    breakBefore: 'auto',
    breakAfter: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 1, 1),
    width: '100%',
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 1px #666',
    padding: theme.spacing(1, 2, 1, 2),
  },
  logo: {
    height: 100,
  },
  text: {
    padding: theme.spacing(0, 0, 0, 2),
  },
  company: {
    fontWeight: '800',
  },
  tagline: {
    textTransform: 'uppercase',
    fontWeight: 300,
  },
}));

const StaticReportHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <img src={logo} alt="Logo" className={classes.logo} />
        <div className={classes.text}>
          <Typography variant="h5" className={classes.company}>Assured Asbestos Abatement</Typography>
          <Typography variant="body1" className={classes.tagline}>
            Inspection, sampling, and removal<br />of all asbestos containing building materials
          </Typography>
        </div>
      </div>
    </div>
  );
};

StaticReportHeader.propTypes = {
};
StaticReportHeader.defaultProps = {
};

export { StaticReportHeader };
