import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format, isToday, isSameDay } from 'date-fns';
import classNames from 'classnames';
import Color from 'color';
import { Droppable } from 'react-beautiful-dnd';
import { Event } from './Event';

const useStyles = makeStyles((theme) => {
  const border = `solid 1px ${theme.palette.border.strong}`;

  return {
    root: {
      minHeight: 100,
      position: 'relative',
      width: '14.28%',
      borderTop: border,
      borderLeft: border,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: theme.spacing(0, 0, 6, 0),
      '&.bottomRow': {
        borderBottom: border,
        '&:first-child': {
          borderBottomLeftRadius: 4,
        },
        '&:last-child': {
          borderBottomRightRadius: 4,
        },
      },
      '&:last-child': {
        borderRight: border,
      },
      '&.hover': {
        cursor: 'pointer',
        backgroundColor: `${theme.palette.border.light}`,
      },
    },
    content: {
      width: '100%',
    },
    events: {
      width: '100%',
    },
    date: {
      position: 'absolute',
      zIndex: 20,
      top: 0,
      right: 0,
      alignSelf: 'flex-end',
      borderBottom: `solid 1px ${theme.palette.border.light}`,
      borderLeft: `solid 1px ${theme.palette.border.light}`,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.type === 'dark' ? `hsla(0, 0%, 20%, 100%)` : `hsla(0, 0%, 90%, 100%)`,
      width: '2.2em',
      color: theme.palette.text.primary,
      '&.today': {
        backgroundColor: theme.palette.type === 'dark' ? Color(theme.palette.secondary.main).desaturate(0.4).string() : Color(theme.palette.primary.main).fade(0.6).string(),
      },
      '& .today': {
        fontWeight: 700,
      },
    },
    dateLabel: {
      fontSize: 14,
    },
    dropzone: {
      zIndex: -1,
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      '&.draggingOver': {
        zIndex: 10,
        backgroundColor: Color(theme.palette.secondary.main).fade(0.6).string(),
      },
    },
  };
});

const Day = (props) => {
  const classes = useStyles(props);
  const { className, date, isBottomRow, onClick, onEventClick, events, hoverEvent, onMouseEnterEvent, onMouseLeaveEvent, onMouseEnter, onMouseLeave, isFirstInRow } = props;
  const [mouseInDay, setMouseInDay] = useState(false);

  const thisDaysEvents = events.filter((event) => {
    if (isSameDay(event.start, date) || isSameDay(event.end, date)) return true;
    if (date >= event.start && date <= event.end) return true;
    return false;
  });

  const { multiDayJobWork, singleDayJobWork, multiDayStats, singleDayStats, multiDayOthers, singleDayOthers } = thisDaysEvents.reduce((acc, event) => {
    if (event.type === 'job-work') {
      if (event.isMultiDay) acc.multiDayJobWork.push(event);
      else acc.singleDayJobWork.push(event);
    } else if (event.type === 'stat-holiday') {
      if (event.isMultiDay) acc.multiDayStats.push(event);
      else acc.singleDayStats.push(event);
    } else {
      if (event.isMultiDay) acc.multiDayOthers.push(event);
      else acc.singleDayOthers.push(event);
    }
    return acc;
  }, { multiDayJobWork: [], singleDayJobWork: [], multiDayStats: [], singleDayStats: [], multiDayOthers: [], singleDayOthers: [] });

  const handleMouseEnter = () => {
    setMouseInDay(true);
    if (onMouseEnter) onMouseEnter(date);
  };
  const handleMouseLeave = () => {
    setMouseInDay(false);
    if (onMouseLeave) onMouseLeave(date);
  };
  const handleMouseEnterEvent = (event) => {
    if (onMouseEnterEvent) onMouseEnterEvent(event);
  };
  const handleMouseLeaveEvent = (event) => {
    if (onMouseLeaveEvent) onMouseLeaveEvent(event);
  };

  const eventProps = {
    onClick: onEventClick,
    onMouseEnter: handleMouseEnterEvent,
    onMouseLeave: handleMouseLeaveEvent,
    date,
    isFirstDayInRow: isFirstInRow,
    isDraggable: true,
  };

  return (
    <div
      className={classNames(className, classes.root, isBottomRow && 'bottomRow', mouseInDay && !hoverEvent && 'hover')}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={classNames(classes.date, isToday(date) && 'today')}>
        <Typography variant="body2" color="inherit" align="center" className={classNames(classes.dateLabel, isToday(date) && 'today')}>
          {format(date, 'd')}
        </Typography>
      </div>

      <div onClick={(e) => e.stopPropagation()} className={classes.content}>
        <Droppable droppableId={`${date}-no-drop`} isDropDisabled={true}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={classNames(classes.events)}
              {...provided.droppableProps}
            >
              {multiDayOthers.map((event, index) => (
                <Event key={event.id} event={event} index={index} hover={hoverEvent && hoverEvent.id === event.id} {...eventProps} />
              ))}
              {singleDayOthers.map((event, index) => (
                <Event key={event.id} event={event} index={index} {...eventProps} />
              ))}
              {multiDayJobWork.map((event, index) => (
                <Event key={event.id} event={event} index={index} hover={hoverEvent && hoverEvent.id === event.id} {...eventProps} />
              ))}
              {singleDayJobWork.map((event, index) => (
                <Event key={event.id} event={event} index={index} {...eventProps} />
              ))}
              {singleDayStats.map((event, index) => (
                <Event key={event.id} event={event} index={index} {...eventProps} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>

        <Droppable droppableId={date.toISOString()}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={classNames(classes.dropzone, snapshot.isDraggingOver && 'draggingOver')}
              {...provided.droppableProps}
            >
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};

// Day.propTypes = {
// };
// Day.defaultProps = {
// };

export { Day };
