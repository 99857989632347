import React, { useState, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormLabel, Button, Typography } from '@material-ui/core';
import { DateRange as DateRangeIcon } from '@material-ui/icons';
import { format, startOfYear, endOfYear, addDays, addWeeks, addMonths, addYears, endOfDay, startOfDay, startOfToday, endOfToday, startOfYesterday, endOfYesterday, startOfTomorrow, endOfTomorrow, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns/esm';
import { makeStyles } from '@material-ui/core/styles';
import { PayPeriodPicker } from './PayPeriodPicker';
import { getPrevPayPeriod, getPayPeriod } from './pay-periods';
import { QueryVariablesContext } from './QueryVariablesContext';
import SimpleMenu from '../SimpleMenu';
import { useAppSettings } from '../contexts/AppSettingsContext';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  trigger: {
    textTransform: 'none',
    fontSize: 18,
    lineHeight: 1.2,
    '& span': {
      textAlign: 'left',
    },
  },
  labelPrimary: {
    lineHeight: 1.2,
  },
  labelSecondary: {
    lineHeight: 1.2,
    marginTop: -4,
    opacity: 1,
    color: '#4A4A4A',
    whiteSpace: 'nowrap',
  },
}));

const formatPayPeriod = (period) => {
  return `${format(period.start, `EEEE MMM d`)} to ${format(period.end, `EEEE MMM d`)}`;
};

const getDatesForRange = (range, payPeriodConfig) => {
  const now = new Date();
  let gte;
  let lte;
  let period;

  switch (range) {
    case 'last-year':
      gte = startOfYear(addYears(now, -1));
      lte = endOfYear(addYears(now, -1));
      break;
    case 'last-3-months':
      gte = startOfMonth(addMonths(now, -2));
      lte = endOfMonth(addMonths(now, 0));
      break;
    case 'last-month':
      gte = startOfMonth(addMonths(now, -1));
      lte = endOfMonth(addMonths(now, -1));
      break;
    case 'last-period':
      period = getPrevPayPeriod(payPeriodConfig, now);
      break;
    case 'last-week':
      gte = startOfWeek(addWeeks(now, -1));
      lte = endOfWeek(addWeeks(now, -1));
      break;
    case 'yesterday':
      gte = startOfYesterday();
      lte = endOfYesterday();
      break;
    case 'today':
      gte = startOfToday();
      lte = endOfToday();
      break;
    case 'this-period':
      period = getPayPeriod(payPeriodConfig, now);
      break;
    case 'this-week':
      gte = startOfWeek(now);
      lte = endOfWeek(now);
      break;
    case 'this-month':
      gte = startOfMonth(now);
      lte = endOfMonth(now);
      break;
    case 'this-year':
      gte = startOfYear(now);
      lte = endOfYear(now);
      break;
    case 'all-time':
    default:
      gte = undefined;
      lte = undefined;
  }

  if (period) {
    return {
      gte: period.start,
      lte: period.end,
    };
  }

  return { gte, lte };
};

const DateRangePicker = ({ path, rootPath, label, initial, onChange, options }) => {
  const { appSettings } = useAppSettings();
  const payPeriodConfig = appSettings.data.payPeriodConfig;
  const classes = useStyles();
  const { updateVariables, variables, loading } = useContext(QueryVariablesContext);
  const boolRootPath = `${rootPath}._and`;

  const [localStateRange, setLocalStateRange] = useState(initial);
  const contextLte = _.get(_.get(variables, boolRootPath, []).find((c) => _.get(c, `${path}._lte`)), `${path}._lte`) || null;
  const contextGte = _.get(_.get(variables, boolRootPath, []).find((c) => _.get(c, `${path}._gte`)), `${path}._gte`) || null;
  const { lte: localStateLte } = getDatesForRange(localStateRange, payPeriodConfig);
  const isLocalStateInSyncWithContext = localStateLte ? localStateLte.toISOString() === contextLte : false;
  const toggleButtonsValue = isLocalStateInSyncWithContext ? localStateRange : null;

  const now = new Date();

  let items = [
    { value: 'all-time', primary: `All Time` },
    { value: 'last-year', primary: `Last Year`, secondary: `${format(addYears(now, -1), `yyyy`)}` },
    { value: 'last-month', primary: `Last Month`, secondary: `${format(addMonths(now, -1), `MMMM`)}` },
    { value: 'last-period', primary: `Last Pay Period`, secondary: `${formatPayPeriod(getPrevPayPeriod(payPeriodConfig, now))}` },
    { value: 'yesterday', primary: `Yesterday`, secondary: `${format(addDays(now, -1), `EEEE`)}` },
    { value: 'today', primary: `Today`, secondary: `${format(now, `EEEE`)}` },
    { value: 'this-period', primary: `This Pay Period`, secondary: `${formatPayPeriod(getPayPeriod(payPeriodConfig, now))}` },
    { value: 'this-month', primary: `This Month`, secondary: `${format(now, `MMMM`)}` },
    { value: 'this-year', primary: `This Year`, secondary: `${format(now, `yyyy`)}` },
  ];

  if (options) items = items.filter((item) => options.includes(item.value));

  items.forEach((item) => {
    item.selected = item.value === toggleButtonsValue;
  });

  const selectedItem = items.find((i) => i.selected);

  const handleChange = (value) => {
    let newRange = value;

    // No change to value, must have been dialog trigger clicked or something
    if (newRange === undefined) return;

    // Toggle to null/all-time if currently selected range selected again
    if (newRange === toggleButtonsValue) newRange = null;

    let rangeObj;
    if (typeof newRange === 'string') {
      setLocalStateRange(newRange);
      rangeObj = getDatesForRange(newRange, payPeriodConfig);
    } else {
      setLocalStateRange('custom-pay-period');
      rangeObj = newRange;
    }

    let { lte, gte } = rangeObj;

    lte = lte && lte.toISOString();
    gte = gte && gte.toISOString();

    updateVariables({
      replaceInArray: {
        rootPath: boolRootPath,
        pathValuePairs: [
          { path: `${path}._lte`, value: lte },
          { path: `${path}._gte`, value: gte },
        ],
      },
    });

    if (onChange) onChange(rangeObj);
  };

  useEffect(() => {
    if (initial && !isLocalStateInSyncWithContext) {
      handleChange(initial);
    }
  }, []); /* eslint-disable-line */

  return (
    <div className={classes.root}>
      <PayPeriodPicker
        onChange={(value) => handleChange(value)}
        payPeriodConfig={payPeriodConfig}
      />
      <FormLabel>{label}</FormLabel>
      <SimpleMenu
        items={[
          ...items,
        ]}
        onChange={(item) => handleChange(item.value)}
        disabled={loading}
        trigger={(
          <Button
            disabled={loading}
            className={classes.trigger}
            variant="contained"
            color="secondary"
          >
            <DateRangeIcon color="inherit" />
            <span>
              <Typography variant="body1" align="left" className={classes.labelPrimary} component="span">
                {selectedItem ? selectedItem.primary : 'Choose date range'}
                {selectedItem && selectedItem.secondary && ': '}
              </Typography>
              {selectedItem && selectedItem.secondary && (
                <Typography variant="body1" align="left" className={classes.labelSecondary} component="span">
                  {selectedItem.secondary}
                </Typography>
              )}
            </span>
          </Button>
        )}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  path: PropTypes.string.isRequired,
  rootPath: PropTypes.oneOf(['where', 'args.where']),
  label: PropTypes.string,
  initial: PropTypes.string,
  options: PropTypes.array,
};

DateRangePicker.defaultProps = {
  label: '',
  rootPath: 'where',
  initial: undefined,
  options: undefined,
};

export { DateRangePicker };
