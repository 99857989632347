import React, { Component, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import produce from 'immer';
import deeplyFlatten from 'flat';

import { mergeSchemas } from 'src/kiska/schema/normalizeSchema';
import { SchemaContext } from '../contexts/SchemaContext';
import { transformNodeValueToFormValue, transformFormValueToMutationVar } from './transforms';
import { atLeastOneError, validate } from './validation';
import { withLog } from '../contexts/LogContext';
import { withControlledLocation } from '../contexts/ControlledLocationContext';
import { withBlockNavigation } from '../contexts/BlockNavigationContext';
import { withApolloClient } from '../contexts/withApolloClient';

const MutationContext = createContext(null);
const useMutationContext = () => useContext(MutationContext);

const splitName = (name) => {
  const nameParts = name.split('.');
  const rootName = nameParts.shift();
  const deepName = nameParts.join('.');

  return { rootName, deepName };
};

class MutationProvider extends Component {
  static contextType = SchemaContext;

  eventListeners = {
    'mutation-start': {},
    'validation-failed': {},
    'validation-passed': {},
    success: {},
    error: {},
    complete: {},
  }

  lastMutationAttempted = null

  cancelMutationHandlers = false

  registeredFields = {}

  constructor(props, context) {
    super(props, context);
    const { node, schemaExtensions, onChangeMutation, onChangeMutationWait, type } = props;

    this.state = {
      pendingMutation: false,
      globalErrors: false,
      fieldValues: {},
      fieldErrors: {},
      fixtures: props.fixtures || {},
      isDirty: false,
    };

    if (schemaExtensions) {
      this.schema = mergeSchemas(context.schema, schemaExtensions);
    } else {
      this.schema = context.schema;
    }

    this.onChangeMutationDebounced = _.debounce(() => this.handleMutate(onChangeMutation), onChangeMutationWait);
    this.liveValidateDebounced = _.debounce(() => this.validate(this.lastMutationAttempted, { live: true }), 500);

    // Copy node into state to support initial field values
    // _.forEach(node, (nodeValue, fieldName) => {
    //   const schemaField = this.schema.types[type].fields[fieldName];
    //   if (!schemaField) return;
    //   const transformFunc = transformNodeValueToFormValue;
    //   const formValue = transformFunc({ nodeValue, node, fieldName, type, schema: this.schema });

    //   if (schemaField.type === 'Json' && formValue) {
    //     // this.state.fieldValues[fieldName] = {};
    //     const flattened = deeplyFlatten(formValue);

    //     _.forEach(flattened, (value, key) => {
    //       key = key.replace(/(^|\.)(\d+)/g, '[$2]'); // replaces array dot notation with []
    //       this.state.fieldValues[`${fieldName}.${key}`] = value;
    //     });
    //   } else {
    //     this.state.fieldValues[fieldName] = formValue;
    //   }
    // });
  }

  getFieldValueFromNode = (fieldName) => {
    const { node, type } = this.props;
    const { rootName, deepName } = splitName(fieldName);
    const nodeValue = node ? node[rootName] : undefined;

    if (nodeValue === undefined) return undefined;

    const schemaField = this.schema.types[type].fields[rootName];
    const transformFunc = transformNodeValueToFormValue;
    const formValue = transformFunc({ nodeValue, node, fieldName, type, schema: this.schema });

    let result;

    if (schemaField.type === 'Json' && formValue) {
      result = _.get(formValue, fieldName);
    } else {
      result = formValue;
    }

    return result;
  }

  // componentDidUpdate = (prevProps) => {
  //   const { node, type } = this.props;

  //   if (prevProps.node !== node) {
  //     this.setState(({ fieldValues: oldFieldValues }) => {
  //       const newFieldValues = {};

  //       _.forEach(node, (nodeValue, fieldName) => {
  //         const schemaField = this.schema.types[type].fields[fieldName];
  //         if (!schemaField) return;
  //         const transformFunc = transformNodeValueToFormValue;
  //         const formValue = transformFunc({ nodeValue, node, fieldName, type, schema: this.schema });

  //         if (schemaField.type === 'Json' && formValue) {
  //           // this.state.fieldValues[fieldName] = {};
  //           const flattened = deeplyFlatten(formValue);

  //           _.forEach(flattened, (value, key) => {
  //             key = key.replace(/(^|\.)(\d+)/g, '[$2]'); // replaces array dot notation with []
  //             this.state.fieldValues[`${fieldName}.${key}`] = value;
  //           });
  //         } else {
  //           this.state.fieldValues[fieldName] = formValue;
  //         }
  //       });
  //     });
  //   }
  // }

  becomeDirty = () => {
    this.setState((state) => {
      if (state.isDirty) return state;

      const schema = this.schema;
      const { type } = this.props;
      const schemaType = schema.types[type];

      const { locationId, requireId } = this.props;
      const unrequireId = requireId({ id: locationId, message: `You may have unsaved change to this ${schemaType.label}.\n\nAre you sure you want to discard any unsaved changes?` });

      return { isDirty: true, unrequireId };
    });
  }

  becomeClean = (cb) => {
    if (!this.state.isDirty) {
      cb();
      return;
    }

    if (this.state.unrequireId) this.state.unrequireId();
    this.setState({ isDirty: false, unrequireId: null }, cb);
  }

  getFieldValue = (name, { asObject } = { asObject: false }) => {
    let value;

    // Never seems to be used? Remove soon?
    // if (asObject) {
    //   const object = this.getAllFieldValues({ jsonFieldsToObjects: true });
    //   value = _.get(object, name);
    //   return value;
    // }

    // First try fixtures
    value = this.state.fixtures[name];
    // Then try existing set value in state
    if (value === undefined) value = this.state.fieldValues[name];
    // Then try node value
    if (value === undefined) {
      value = this.getFieldValueFromNode(name);
    }
    // Then try initial value -- will only work if field has already been registered
    if (value === undefined && this.registeredFields[name]) value = this.registeredFields[name].initialValue;

    return value;
  }

  setFieldValue = (name, value) => {
    if (this.state.fixtures[name]) return;
    this.setState(produce((draft) => {
      draft.fieldValues[name] = value;
    }));
  }

  getFieldErrors = (name) => {
    const errors = this.state.fieldErrors[name] || false;
    return errors;
  }

  setErrors = (errors = {}) => {
    this.setState(produce((draft) => {
      draft.globalErrors = errors.globalErrors || false;

      this.forEachField(({ name }) => {
        if (!errors.fieldErrors) {
          draft.fieldErrors[name] = false;
          return;
        }
        draft.fieldErrors[name] = errors.fieldErrors[name] || false;
      });
    }));
  }

  clearErrors = () => this.setErrors();

  forEachField = (func) => {
    _.forEach(this.registeredFields, (field, name) => {
      const value = this.getFieldValue(name);
      const errors = this.getFieldErrors(name);
      func({ name, value, errors });
    });
    _.forEach(this.state.fixtures, (value, name) => {
      func({ name, value, errors: {} });
    });
  }

  mapFields = (func) => {
    return _.map(this.registeredFields, (field, name) => {
      const value = this.getFieldValue(name);
      const errors = this.getFieldErrors(name);
      func(name, value, errors);
    });
  }

  getAllFieldValues = ({ jsonFieldsToObjects, includeNode }) => {
    const values = includeNode ? _.merge({}, this.props.node) : {};

    this.forEachField(({ name, value }) => {
      if (value === undefined) return;
      if (!jsonFieldsToObjects) {
        values[name] = value;
      } else {
        _.set(values, name, value);
      }
    });

    return values;
  }

  componentWillUnmount = () => {
    if (this.state.unrequireId) this.state.unrequireId();
    this.cancelMutationHandlers = true;
    if (this.onChangeMutationDebounced) this.onChangeMutationDebounced.cancel();
    if (this.liveValidateDebounced) this.onChangeMutationDebounced.cancel();
  }

  handleDeepDelete = (name) => {
    this.setState(produce((draft) => {
      // Loop through all registered fields that are decendents of passed in "name" and set them to undefined
      this.forEachField(({ name: deepName }) => {
        if (!_.startsWith(deepName, name)) return;
        delete draft.fieldValues[deepName];
        delete this.registeredFields[deepName];
      });

      // Same for state values
      _.forEach(draft.fieldValues, (value, deepName) => {
        if (!_.startsWith(deepName, name)) return;
        delete draft.fieldValues[deepName];
        delete this.registeredFields[deepName];
      });
    }));
  }

  handleChange = (name, value, { flatten, notDirty } = { flatten: false }) => {
    const schema = this.schema;
    const { liveValidation, onChangeMutation, type } = this.props;
    const schemaField = schema.types[type].fields[name];

    if (!schemaField.neverDirties) {
      let shouldBecomeDirty = false;
      const currentValue = this.getFieldValue(name);

      if (value instanceof Date && currentValue instanceof Date) {
        if (currentValue.getTime() !== value.getTime())shouldBecomeDirty = true;
      } else if (typeof value === 'object') shouldBecomeDirty = true;
      else if (currentValue !== value) shouldBecomeDirty = true;

      if (shouldBecomeDirty) this.becomeDirty();
    }

    if (!flatten) {
      this.setFieldValue(name, value);
    }

    if (flatten) {
      const flattened = deeplyFlatten(value);

      this.setState(produce((draft) => {
        _.forEach(flattened, (deepValue, deepName) => {
          const fullName = `${name}.${deepName}`.replace(/(^|\.)(\d+)/g, '[$2]'); // replaces array dot notation with []
          draft.fieldValues[fullName] = deepValue;
        });
      }));
    }

    if (onChangeMutation) {
      this.onChangeMutationDebounced();
    } else if (liveValidation && this.lastMutationAttempted) {
      this.liveValidateDebounced(this.lastMutationAttempted, true);
    }
  }

  fieldValuesToMutationVars = (valuesWithJsonToObjects) => new Promise((resolveVars, rejectVars) => {
    const schema = this.schema;
    const { type, node } = this.props;

    // Get array of promises that will resolve to objects containing the field name and it's associated mutation var
    const promises = _.map(valuesWithJsonToObjects, (value, name) => new Promise((resolve, reject) => {
      const transformFunc = _.get(this.registeredFields, `${name}.transforms.formValueToMutationVar`) || transformFormValueToMutationVar;

      transformFunc({ formValue: value, fieldName: name, node, type, schema })
        .then((mutationVar) => {
          resolve({ name, mutationVar });
        })
        .catch((error) => reject(error));
    }));

    // Resolve all the promises and build the full object of mutation vars keyed by field name
    Promise.all(promises)
      .then((results) => {
        const vars = {};
        results.forEach(({ name, mutationVar }) => {
          vars[name] = mutationVar;
        });
        resolveVars(vars);
      })
      .catch((error) => {
        rejectVars(error);
      });
  })

  nextEventListenerId = 0

  addEventListener = (eventType, listener) => {
    if (!this.eventListeners[eventType]) throw new Error(`Unknown event type "${eventType}"`);
    this.nextEventListenerId += 1;
    this.eventListeners[eventType][this.nextEventListenerId] = listener;
    return this.nextEventListenerId;
  }

  removeEventListener = (eventType, id) => {
    delete this.eventListeners[eventType][id];
  }

  dispatchEvent = (eventType, ...args) => {
    _.forEach(this.eventListeners[eventType], (listener) => {
      if (listener && typeof listener === 'function') listener(...args);
      else console.warn(`Invalid MutationContext listener function "${listener}" for event type "${eventType}"`);
    });
  }

  handleMutationStart = (mutation) => {
    this.dispatchEvent('mutation-start', mutation);
  }

  handleMutationSuccess = (mutation, result) => {
    this.becomeClean(() => {
      const { onSuccess, log } = this.props;
      console.log('Mutation completed successfully.');

      log.captureEvent({ eventName: 'MutationContext.handleMutationSuccess', variables: mutation.variables, type: mutation.type, operation: mutation.operation }, 'info');

      if (this.cancelMutationHandlers) return;

      if (!(mutation.meta.quiet)) {
        this.dispatchEvent('success', mutation, result);
        if (onSuccess) {
          onSuccess(mutation, result);
        }
      }
    });
  }

  handleMutationError = (mutation, error, result) => {
    const { log } = this.props;

    log.error('Mutation Context Error', { error });
    console.error('Mutation error: ', error);
    console.error('GraphQl error: ', error.graphQLErrors);
    if (this.cancelMutationHandlers) return;

    const { onError } = this.props;

    this.dispatchEvent('error', mutation, error);
    if (onError) {
      onError(mutation, error);
    }
  }

  handleMutationComplete = (mutation) => {
    if (this.cancelMutationHandlers) return;
    setTimeout(() => {
      if (!this.cancelMutationHandlers) this.setState({ pendingMutation: false });
    }, 500);
    this.dispatchEvent('complete', mutation);
  }

  validate = async (mutation, { live }) => {
    const schema = this.schema;
    const { node, type, log } = this.props;
    const values = this.getAllFieldValues({ jsonFieldsToObjects: false });

    if (mutation.requiresValidation === false) {
      return true;
    }

    const validationErrors = await validate({ type, values, schema, node });

    if (atLeastOneError(validationErrors)) {
      if (!live) {
        log.info('<MutationContext> validation failed', validationErrors);
        console.log('Mutation attempt failed validation: ', validationErrors);
        this.dispatchEvent('validation-failed', mutation, validationErrors, { live: false });
      }
      this.setErrors(validationErrors);
      return false;
    }

    if (!live) {
      this.dispatchEvent('validation-passed', mutation);
    }

    this.dispatchEvent('validation-attempted', mutation, validationErrors, { live });

    this.clearErrors();

    return true;
  }

  handleMutate = async (mutationName, mutationMeta) => {
    const { updateFunction: propsUpdateFunction, embeddedMode } = this.props;
    const schema = this.schema;
    const { pendingMutation } = this.state;
    if (pendingMutation) return;
    this.setState({ pendingMutation: true });

    const { node, client, type, log, modifyMutationVars } = this.props;
    const buildMutation = this.props.mutationBuilders[mutationName];
    const values = this.getAllFieldValues({ jsonFieldsToObjects: true });
    const valuesAsMutationVars = await this.fieldValuesToMutationVars(values);

    const mutation = await buildMutation({ type, node, schema, values, valuesAsMutationVars });
    mutation.meta = mutationMeta || {};

    this.lastMutationAttempted = mutation;

    log.captureEvent({ eventName: 'Prepared for <MutationContext> mutate', values, valuesAsMutationVars }, 'info');

    const validationPassed = await this.validate(mutation, { live: false });
    if (!validationPassed) {
      setTimeout(() => {
        const firstError = window.document.querySelector('.Mui-error');
        if (firstError) firstError.parentElement.scrollIntoView({ behavior: 'smooth' });
      }, 1000);
      this.handleMutationComplete(mutation);
      return;
    }

    if (!mutation.variables) {
      mutation.variables = valuesAsMutationVars;
    }

    if (modifyMutationVars) {
      mutation.variables = modifyMutationVars({ node, values, variables: mutation.variables });
    }

    this.handleMutationStart(mutation);

    if (embeddedMode) {
      this.handleMutationSuccess(mutation, { node, values, valuesAsMutationVars });
      this.handleMutationComplete(mutation);
      return;
    }

    const updateFunction = propsUpdateFunction || ((cache, { data }) => {

    });

    const mutateFunc = mutation.mutate || client.mutate;
    mutateFunc({
      mutation: mutation.query,
      variables: mutation.variables,
      update: updateFunction,
    })
      .then((result) => this.handleMutationSuccess(mutation, result))
      .catch((error, result) => this.handleMutationError(mutation, error, result))
      .finally(() => this.handleMutationComplete(mutation));
  }

  getDefaultFieldValue = (name, options = {}) => {
    const schema = this.schema;
    const { type } = this.props;

    const schemaDefault = schema.types[type].fields[name].defaultValue;
    if (schemaDefault !== undefined) return schemaDefault;

    const schemaFieldType = schema.types[type].fields[name].type;

    if (schemaFieldType) {
      switch (schemaFieldType) {
        case 'ID': return '';
        case 'String': return '';
        case 'Int': return 0;
        case 'Float': return 0;
        case 'Timestamp': return new Date();
        case 'Date': return new Date();
        case 'Time': return new Date();
        case 'Boolean': return false;
        case 'Json': return null;
        default: return null;
      }
    }

    return null;
  }

  getInitialFieldValue = (name, options = {}) => {
    const schema = this.schema;
    const { node, type, initialValues } = this.props;

    const { rootName, deepName } = splitName(name);

    if (name === 'tags') {
      console.log('tags');
    }

    if (node && node.id) {
      const nodeValue = node[rootName];
      const transformFunc = _.get(options, 'transforms.nodeValueToFormValue') || transformNodeValueToFormValue;
      let value = transformFunc({ nodeValue, node, fieldName: rootName, type, schema });
      if (!deepName) return value;
      value = _.get(value, deepName);
      if (value !== undefined) return value;
    }

    if (options.initialValue !== undefined) return options.initialValue;
    if (initialValues && initialValues[name] !== undefined) return initialValues[name];

    return this.getDefaultFieldValue(name, options);
  }

  registerField = (name, options = { transforms: {}, forceReregister: false, initialValue: undefined }) => {
    if (this.registeredFields[name] && !options.forceReregister) return;

    if (options.schemaExtensions) {
      this.schema = mergeSchemas(this.schema, options.schemaExtensions);
    }

    const schema = this.schema;
    const { type } = this.props;

    if (!_.get(schema.types, `${type}.fields`)) {
      throw new Error(`Registering field "${name}" for node type "${type}" in MutationForm but "${type}" does not appear in schema.`);
    } else if (!schema.types[type].fields[name]) {
      throw new Error(`Registering field "${name}" for node type "${type}" in MutationForm but it does not appear in schema.`);
    }

    const initialValue = this.getInitialFieldValue(name, options);

    this.registeredFields[name] = {
      initialValue,
      transforms: options.transforms,
    };
    this.forceUpdate();
  }

  unregisterField = (name) => {
    delete this.registeredFields[name];
    this.setState(produce((draft) => {
      delete draft.fieldValues[name];
      delete draft.fieldErrors[name];
    }));
  }

  runDefaultMutation = (mutationMeta) => {
    const { defaultMutation, log } = this.props;
    if (defaultMutation) this.handleMutate(defaultMutation, mutationMeta);
    else {
      console.warn(`No default mutation to run.`);
      log.warning('<MutationContext> No default mutation to run.');
    }
  }

  createContextValue = () => {
    const schema = this.schema;
    const { globalErrors, pendingMutation } = this.state;
    const { node, type, blurMode } = this.props;

    return {
      // Functions
      onChange: this.handleChange,
      mutate: this.handleMutate,
      registerField: this.registerField,
      unregisterField: this.unregisterField,
      addEventListener: this.addEventListener,
      removeEventListener: this.removeEventListener,
      runDefaultMutation: this.runDefaultMutation,
      onDeepDelete: this.handleDeepDelete,

      // Vars
      globalErrors,
      getAllFieldValues: this.getAllFieldValues,
      getFieldValue: this.getFieldValue,
      getFieldErrors: this.getFieldErrors,
      node,
      pendingMutation,
      schema,
      schemaType: schema.types[type],
      type,
      fixtures: this.state.fixtures,
      blurMode,
    };
  }

  render() {
    const { children } = this.props;
    const contextValue = this.createContextValue();

    return (
      <MutationContext.Provider value={contextValue}>
        {children}
      </MutationContext.Provider>
    );
  }
}

MutationProvider.propTypes = {
  schemaExtensions: PropTypes.object,
  liveValidation: PropTypes.bool,
  type: PropTypes.string.isRequired,
  node: PropTypes.object,
  client: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  defaultMutation: PropTypes.string,
  children: PropTypes.node,
  mutationBuilders: PropTypes.object.isRequired,
  onChangeMutation: PropTypes.string,
  onChangeMutationWait: PropTypes.number,
  fixtures: PropTypes.object,
  initialValues: PropTypes.object,
  log: PropTypes.object.isRequired,
  blurMode: PropTypes.bool,
  locationId: PropTypes.string,
  requireId: PropTypes.func.isRequired,
  modifyMutationVars: PropTypes.func,
  updateFunction: PropTypes.func,
};

MutationProvider.defaultProps = {
  liveValidation: true,
  modifyMutationVars: undefined,
  schemaExtensions: undefined,
  node: undefined,
  onSuccess: null,
  defaultMutation: '',
  children: null,
  onError: null,
  onChangeMutation: undefined,
  onChangeMutationWait: 2000,
  fixtures: undefined,
  initialValues: {},
  blurMode: false,
  locationId: undefined,
  updateFunction: undefined,
};

MutationProvider = _.flow(
  withApolloClient,
  withLog,
  withControlledLocation,
  withBlockNavigation,
)(MutationProvider);

export { MutationProvider, MutationContext, useMutationContext };
