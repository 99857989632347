export const DIALOG_WIDTH = 320;

export const DIALOG_WIDTH_WIDER = 325;

export const VIEW_HEIGHT = 358;

export const DAY_SIZE = 36;

export const DAY_MARGIN = 2;

export const IS_TOUCH_DEVICE_MEDIA = '@media (pointer: fine)';
