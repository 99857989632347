import React from 'react';
import { fragments } from './fragments';
import { yesNoOptions } from './utils';

export default {
  types: {
    job: {
      renderers: {
        inline: ({ node }) => <>{node.shortId} - {node.title}</>,
      },

      selections: {
        default: `
          ${fragments.job.basic}
          tasks(order_by: [{task: {label: asc}}]) { id task {
            ${fragments.task.basic}
          }}
          files(order_by: {file: {updatedAt: desc}}) { id file {
            ${fragments.file.basic}
          }}
          forms {id form{id title jobs {id job{id}}}}
          tags {id tag {id label type color icon}}
          firstWorkEntry: workEntries(limit: 1, order_by: [{start: asc}]){
            start end
            user {
              id displayName email
              avatar {id url mimeType name }
            }
          }
          lastWorkEntry: workEntries(limit: 1, order_by: [{start: desc}]){
            start end
            user {
              id displayName email
              avatar {id url mimeType name }
            }
          }
          workEntries_aggregate {
            aggregate {
              sum {
                duration
              }
            }
          }
          assignedUsers {id user {
            id displayName email
            avatar {id url mimeType name }
          }}
          notedWorkEntries: workEntries(where: {notes: { _neq: "" }}){
            id start end duration notes
            user {id displayName avatar{id url}}
          }
          expenses_aggregate{aggregate{count}}
          change_orders_aggregate: forms_aggregate(where: {form: {name:{_eq: "ChangeOrderForm"}}}){aggregate{count}}
          resources{id resource{id title description link createdAt updatedAt userId expires files}}
          resources_aggregate{aggregate{count}}
        `,
      },

      fields: {
        files: {
          type: 'file',
          hasMany: true,
        },

        shortId: {
          type: 'String',
          label: 'Code',
          validation: {
            required: true,
          },
        },

        title: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        options: {
          type: 'Json',
        },

        specs: {
          type: 'Json',
        },

        customer: {
          type: 'contact',
          validation: {
            required: true,
          },
        },

        tasks: {
          type: 'task',
          hasMany: true,
          validation: {
            required: true,
          },
        },

        tags: {
          type: 'tag',
          hasMany: true,
          validation: { required: false },
        },

        forms: {
          type: 'form',
          hasMany: true,
          validation: { required: false },
        },

        assignedUsers: {
          type: 'user',
          hasMany: true,
          validation: { required: false },
        },

        resources: {
          type: 'resource',
          hasMany: true,
          validation: { required: false },
        },

        status: {
          type: 'String',
          options: [
            { value: 'active', primary: 'Active' },
            { value: 'archived', primary: 'Archived' },
          ],
          defaultValue: 'active',
          validation: {
            required: true,
          },
        },

        type: {
          type: 'String',
          validation: { required: true },
          options: [
            { value: 'asbestos', primary: 'Asbestos Abatement' },
            { value: 'asbestos-sampling', primary: 'Asbestos Sampling' },
            { value: 'painting', primary: 'Painting' },
            { value: 'cleaning', primary: 'Cleaning' },
            { value: 'general', primary: 'General' },
            { value: 'office-admin', primary: 'Office Admin' },
          ],
        },

        'specs.marketType': {
          type: 'String',
          // validation: { requiredIf: { dep: 'specs.jobAddressSameAsCustomer', oneOf: ['septic'] } },
          options: [
            { value: 'residential', primary: 'Residential' },
            { value: 'commercial', primary: 'Commercial' },
            { value: 'other', primary: 'Other' },
          ],
        },
        'specs.workOrderType': { type: 'String', validation: { required: true } },
        'specs.customerPo': { type: 'String', validation: { required: false } },

        'specs.callAhead': { type: 'String', validation: { required: false } },
        'specs.callAheadBestTime': { type: 'String', validation: { required: false } },

        'specs.ferryRequired': { type: 'String', defaultValue: 'no', validation: { required: false }, options: yesNoOptions },

        'specs.billingInfoSameAsCustomer': { type: 'String', defaultValue: 'yes', validation: { required: true }, options: yesNoOptions },
        'specs.billingInfo': { type: 'String', validation: { requiredIf: { dep: 'specs.billingInfoSameAsCustomer', eq: 'no' } } },
        'specs.jobAddressSameAsCustomer': { type: 'String', defaultValue: 'yes', validation: { required: true }, options: yesNoOptions },
        'specs.jobAddress': { type: 'String', validation: { requiredIf: { dep: 'specs.jobAddressSameAsCustomer', eq: 'no' } } },

        'specs.jobDescription': { type: 'String', validation: { required: false } },
        'specs.workDone': { type: 'String', validation: { required: false } },

        'specs.havePermit': { type: 'String', validation: { required: false } },
        'specs.permitNumber': { type: 'String', validation: { requiredIf: { dep: 'specs.havePermit', eq: 'yes' } } },
        'specs.contractorAuthorization': { type: 'String', validation: { required: false } },

        'specs.amps': { type: 'String', validation: { required: false } },
        'specs.volts': { type: 'String', validation: { required: false } },
        'specs.phase': { type: 'String', validation: { required: false } },

        'specs.freightCharges': { type: 'String', defaultValue: 'no', validation: { required: false } },
        'specs.freightChargesDetails': { type: 'String', validation: { requiredIf: { dep: 'specs.freightCharges', eq: 'yes' } } },

        'specs.materials': { type: 'String', validation: { required: false } },

        'specs.subcontractorsUsed': { type: 'String', validation: { required: false } },
        'specs.subcontractorsDetails': { type: 'String', validation: { requiredIf: { dep: 'specs.subcontractorsUsed', eq: 'yes' } } },

        'specs.equipmentRentals': { type: 'String', validation: { required: false } },
        'specs.equipmentRentalsDetails': { type: 'String', validation: { requiredIf: { dep: 'specs.equipmentRentals', eq: 'yes' } } },

        'specs.billStatus': {
          type: 'String',
          defaultValue: 'not-billed',
          validation: { required: true },
          options: [
            { value: 'not-billed', primary: 'Not Invoiced Yet' },
            { value: 'billed', primary: 'Invoice Sent' },
            { value: 'payment-received', primary: 'Payment Received' },
          ],
        },
        'specs.permitPrice': { type: 'String', validation: { number: { gte: 0 }, required: false } },
        'specs.permitDeclaration': { type: 'String', validation: { required: false } },
        'specs.bookkeeperNotes': { type: 'String', validation: { required: false } },
        'specs.officeManagerNotes': { type: 'String', validation: { required: false } },
        'specs.jobTracking': { type: 'String', validation: { required: false } },

        'specs.asbestos.risk': {
          type: 'String',
          options: [
            { value: 'moderate', primary: 'Moderate Risk', color: 'hsla(60, 100%, 25%, .5)' },
            { value: 'high', primary: 'High Risk', color: 'hsla(0, 100%, 25%, .5)' },
          ],
          validation: { requiredIf: { dep: 'specs.jobType', eq: 'asbestos' } },
        },
        'specs.asbestos.materialType': {
          type: 'String',
          options: [
            // Moderate risk
            { value: 'drywall', primary: 'Drywall', secondary: 'Moderate Risk', riskLevel: 'moderate' },
            { value: 'vft', primary: 'Vinyl Floor Tile', secondary: 'Moderate Risk', riskLevel: 'moderate' },
            { value: 'stucco', primary: 'Stucco', secondary: 'Moderate Risk', riskLevel: 'moderate' },
            // High risk
            { value: 'vsf', primary: 'Vinyl Sheet Flooring', secondary: 'High Risk', riskLevel: 'high' },
            { value: 'ceiling-texture', primary: 'Ceiling Texture', secondary: 'High Risk', riskLevel: 'high' },
            { value: 'ceiling-drywall', primary: 'Ceiling Drywall', secondary: 'High Risk', riskLevel: 'high' },
            { value: 'vermiculite', primary: 'Vermiculite', secondary: 'High Risk', riskLevel: 'high' },
            { value: 'duct-wrap', primary: 'Duct Wrap', secondary: 'High Risk', riskLevel: 'high' },
          ],
          validation: { requiredIf: { dep: 'specs.jobType', eq: 'asbestos' } },
        },
        'specs.painting.location': {
          type: 'String',
          validation: { requiredIf: { dep: 'specs.jobType', eq: 'painting' } },
          options: [
            { value: 'interior', label: 'Interior' },
            { value: 'exterior', label: 'Exterior' },
          ],
        },
        'specs.painting.area': { type: 'Int', validation: { requiredIf: { dep: 'specs.jobType', eq: 'Xpainting' } } },
        'specs.painting.workingAtHeights': { type: 'String', options: yesNoOptions, validation: { requiredIf: { dep: 'specs.jobType', eq: 'Xpainting' } } },

        'specs.wcbReports.projectLocation': { type: 'String' },
        'specs.wcbReports.assessmentDate': { type: 'Date' },
        'specs.wcbReports.hiredBy': { type: 'String' },
        'specs.wcbReports.removalArea': { type: 'String' },
        'specs.wcbReports.removalProcedure': { type: 'String' },
        'specs.wcbReports.samplesTakenBy': { type: 'String' },
        'specs.wcbReports.sampleResults': { type: 'String' },
        'specs.wcbReports.sampleId': { type: 'String' },
        'specs.wcbReports.materialCondition': { type: 'String' },
        'specs.wcbReports.friability': { type: 'String' },
        'specs.wcbReports.riskToPersonsOutside': { type: 'String' },
        'specs.wcbReports.ahearaBuildingInspector': { type: 'String' },
        'specs.wcbReports.coverImage': { type: 'Json', embedded: true },
        'specs.wcbReports.assessmentBy': { type: 'String' },
        'specs.wcbReports.reasonForSurvey': { type: 'String' },
        'specs.wcbReports.labName': { type: 'String' },
        'specs.wcbReports.riskComplianceMeasures': { type: 'String' },
        'specs.wcbReports.analyticalResultsFiles': { type: 'Json', hasMany: true, embedded: true },
        'specs.wcbReports.airMonitoring': { type: 'String' },

        'specs.dateDepositReceived': {
          type: 'String',
        },
        'specs.depositReceivedVia': {
          type: 'String',
          options: [
            { value: 'not-received', primary: 'Not Received Yet' },
            { value: 'cheque', primary: 'Cheque' },
            { value: 'etransfer', primary: 'E-Transfer' },
            { value: 'other', primary: 'Other' },
          ],
          validation: { requiredIf: { dep: 'specs.dateDepositReceived', isTruthy: true } },
        },
      },
    },
  },
};
