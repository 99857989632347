import React, { useState, useContext, useLayoutEffect, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { NodeListWithVars } from 'src/kiska/components/NodeList/NodeListWithVars';
import Table from 'src/kiska/components/NodeList/Table';
import { Add as AddIcon, Edit as EditIcon } from '@material-ui/icons';
import classNames from 'classnames';
import _ from 'lodash';
import LikeFilter from 'src/kiska/components/NodeList/LikeFilter';
import { useHistory } from 'react-router-dom';
import { useNodeList } from 'src/kiska/components/NodeList';
import { generatePrimaryId, wrap } from 'src/kiska/utils';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { FloatingButton } from 'src/components/FloatingButton';
import { useViewOrUpdate } from 'src/kiska/components/useViewOrUpdate';
import { useMutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import { useLocalNode } from 'src/kiska/components/LocalNodeContext';
import { TaskUpdateForm } from './TaskUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  editCell: {
    textAlign: 'right !important',
    '& > button': {
      padding: 6,
    },
  },
  table: {
    '& tr .table-row-edit-cell button': {
      opacity: 0.4,
    },
    '& tr:hover .table-row-edit-cell button': {
      opacity: 1,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 0),
  },
  searchBox: {
    padding: theme.spacing(0, 0, 0, 0),
    margin: theme.spacing(0, 0, 2, 0),
    '& svg': {
      margin: theme.spacing(0, 0, 0, -1),
    },
  },
  searchInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: 20,
  },
  filters: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SimpleTableInner = (props) => {
  const history = useHistory();
  const { embeddedMode, name, initialValue, columns: propsColumns, type, nodes: propsNodes, insertInitialValues, tableProps, floatingAdd, filters, showSearchBox, viewDialogProps, searchPaths, searchPlaceholder, clickUrl, addLabel, rowClickAction, updateFixtures, insertFixtures, ViewDialog, actionsOnBottom, actionsOnTop, enableEdit, enableAdd, UpdateForm, nodeListProps, initial, updateFormProps } = props;
  const classes = useStyles(props);
  const nodeListContext = useNodeList();
  const [focusedRowIndex, setFocusedRowIndex] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [activeEditNode, setActiveEditNode] = useState(null);
  const [activeViewNode, setActiveViewNode] = useState(null);
  const { printMode } = usePrint();
  const mutationContext = useMutationContext();
  const rootRef = useRef();
  const { update } = useViewOrUpdate();
  const { onChange, getFieldValue, registerField, unregisterField, mutate } = update ? mutationContext : {};
  const localNode = useLocalNode();
  const nodes = useMemo(() => {
    let result = [];
    if (embeddedMode) result = _.get(localNode.node, name);
    else if (propsNodes) result = propsNodes;
    else if (nodeListContext) result = nodeListContext.nodes;
    return result;
  }, [embeddedMode, localNode, name, nodeListContext, propsNodes]);

  const values = getFieldValue ? getFieldValue(name) : _.get(localNode, `node.${name}`);

  useEffect(() => {
    if (update) {
      registerField(name, { initialValue });

      return () => {
        unregisterField(name);
      };
    }
    return undefined;
  }, [initialValue, name, registerField, unregisterField, update]);

  const updateOrCreateProps = activeEditNode && activeEditNode.id
    ? { fixtures: updateFixtures }
    : {
      fixtures: insertFixtures,
      initialValues: insertInitialValues,
    };

  useLayoutEffect(() => {
    if (focusedRowIndex === null) return;

    if (focusedRowIndex >= nodes.length) {
      setFocusedRowIndex(0);
    }
  }, [focusedRowIndex, nodes]);

  const handleAddClick = () => {
    setActiveEditNode(null);
    setEditDialogOpen(true);
  };

  const handleEditClick = (event, node) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setActiveEditNode(node);
    setEditDialogOpen(true);
  };

  const handleNodeChange = (node) => {
    setActiveEditNode(node);
  };

  const handleRowClick = (node) => {
    if (clickUrl) {
      history.push(clickUrl(node));
    } else if (rowClickAction === 'edit') {
      handleEditClick(null, node);
    } else if (rowClickAction === 'view' && ViewDialog) {
      setActiveViewNode(node);
      setViewDialogOpen(true);
    }
  };

  const handleEditDialogClose = () => {
    setActiveEditNode(null);
    setEditDialogOpen(false);
  };

  const handleViewDialogClose = () => {
    setActiveViewNode(null);
    setViewDialogOpen(false);
  };

  const handleSearchKeyPress = (event) => {
    if (nodes && nodes.length) {
      switch (event.key) {
        case 'Enter': {
          const node = nodes[focusedRowIndex];
          handleRowClick(node);
          break;
        }
        case 'ArrowUp':
          setFocusedRowIndex(wrap(focusedRowIndex - 1, nodes.length));
          break;
        case 'ArrowDown':
          setFocusedRowIndex(wrap(focusedRowIndex + 1, nodes.length));
          break;
        default:
      }
    }
  };

  const handleSearchFocus = () => {
    if (nodes && nodes.length) {
      setFocusedRowIndex(0);
    }
  };

  const handleSearchBlur = () => {
    setFocusedRowIndex(null);
  };

  const columns = [
    ...propsColumns,
  ];

  if (enableEdit && !printMode) {
    columns.push({
      label: '',
      fieldName: 'edit',
      className: classNames(classes.editCell, 'table-row-edit-cell'),
      Renderer: ({ parentNode }) => <IconButton onClick={(event) => handleEditClick(event, parentNode)} className={classes.editButton}><EditIcon /></IconButton>,
    });
  }

  const actions = (
    <div className={classes.actions}>
      {enableAdd && (
        <Button onClick={handleAddClick} color="primary" variant="contained">
          <AddIcon /><span>{addLabel}</span>
        </Button>
      )}
    </div>
  );

  const handleSuccess = (mutation, { values: newValue, valuesAsMutationVars }) => {
    if (!embeddedMode) return;
    if (mutation.operation === 'insert') {
      newValue.id = generatePrimaryId();
      onChange(name,
        [...(values || []), newValue]);
    } else if (mutation.operation === 'update') {
      newValue.id = activeEditNode.id;
      onChange(name,
        values.map((value) => {
          if (value.id === newValue.id) return newValue;
          return value;
        }));
    } else if (mutation.operation === 'delete') {
      const newValues = values.map((value) => {
        if (value.id === activeEditNode.id) return undefined;
        return value;
      }).filter((v) => v !== undefined);
      console.log(newValues);
      onChange(name, newValues);
    }
  };

  return (
    <>
      {(enableEdit || enableAdd) && (
        <UpdateForm
          id={activeEditNode && activeEditNode.id}
          open={editDialogOpen}
          onClose={handleEditDialogClose}
          onNodeChange={handleNodeChange}
          node={activeEditNode}
          embeddedMode={embeddedMode}
          onSuccess={handleSuccess}
          {...updateOrCreateProps}
          {...updateFormProps}
        />
      )}
      {ViewDialog && <ViewDialog open={viewDialogOpen} node={activeViewNode} onClose={handleViewDialogClose} {...viewDialogProps} />}

      <div className={classes.root} ref={rootRef}>
        {actionsOnTop && actions}
        {filters && (
          <Grid container spacing={1} alignItems="flex-start" className={classes.filters}>
            {filters}
          </Grid>
        )}
        {showSearchBox && (
          <div style={{ maxWidth: 600 }}>
            <LikeFilter
              className={classes.searchBox}
              paths={searchPaths}
              search
              label=""
              placeholder={searchPlaceholder}
              searchInputClassName={classes.searchInput}
              textFieldProps={{
                onKeyDown: handleSearchKeyPress,
                onBlur: handleSearchBlur,
                onFocus: handleSearchFocus,
              }}
            />
          </div>
        )}
        <Table
          columns={columns}
          onRowClick={(node) => handleRowClick(node)}
          className={classes.table}
          focusedRowIndex={focusedRowIndex}
          type={type}
          nodes={nodes}
          {...tableProps}
        />
        {actionsOnBottom && actions}
        {enableAdd && floatingAdd && (
          <FloatingButton
            variant="extended"
            color="primary"
            containerRef={rootRef}
            onClick={handleAddClick}
          >
            <AddIcon /><span>{addLabel}</span>
          </FloatingButton>
        )}
      </div>
    </>
  );
};

const SimpleTable = (props) => {
  const { type, initial, fixtures, nodeListProps } = props;

  return (
    <NodeListWithVars type={type} initial={initial} fixtures={fixtures} {...nodeListProps}>
      <SimpleTableInner {...props} />
    </NodeListWithVars>
  );
};

SimpleTable.propTypes = {
  addLabel: PropTypes.string,
  columns: PropTypes.array.isRequired,
  UpdateForm: PropTypes.elementType,
  type: PropTypes.string.isRequired,
  tableProps: PropTypes.object,
  nodeListProps: PropTypes.object,
  initial: PropTypes.object,
  updateFormProps: PropTypes.object,
  enableAdd: PropTypes.bool,
  enableEdit: PropTypes.bool,
  actionsOnTop: PropTypes.bool,
  actionsOnBottom: PropTypes.bool,
  ViewDialog: PropTypes.elementType,
  rowClickAction: PropTypes.oneOf(['view', 'edit']),
  fixtures: PropTypes.any,
  clickUrl: PropTypes.func,
  showSearchBox: PropTypes.bool,
  searchPaths: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  updateFixtures: PropTypes.object,
  insertFixtures: PropTypes.object,
  filters: PropTypes.node,
  floatingAdd: PropTypes.bool,
  viewDialogProps: PropTypes.object,
  insertInitialValues: PropTypes.object,
};
SimpleTable.defaultProps = {
  UpdateForm: undefined,
  addLabel: 'Add',
  tableProps: undefined,
  nodeListProps: undefined,
  initial: undefined,
  updateFormProps: undefined,
  enableAdd: true,
  enableEdit: true,
  actionsOnTop: false,
  actionsOnBottom: false,
  ViewDialog: undefined,
  rowClickAction: 'view',
  fixtures: undefined,
  clickUrl: undefined,
  showSearchBox: false,
  searchPaths: undefined,
  searchPlaceholder: 'Search',
  updateFixtures: undefined,
  insertFixtures: undefined,
  filters: undefined,
  floatingAdd: true,
  viewDialogProps: undefined,
  insertInitialValues: undefined,
};

export { SimpleTable, SimpleTableInner };
