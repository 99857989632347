import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import _ from 'lodash';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { NodeListContext } from '.';
import { QueryVariablesContext } from './QueryVariablesContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ToggleButtonOptionsFilter = ({ className, options: propsOptions, label, path, rootPath, ...nodeSelectorProps }) => {
  const client = useApolloClient();
  const queryVariablesContext = useContext(QueryVariablesContext);
  const nodeListContext = useContext(NodeListContext);
  const { updateVariables, variables, schemaType, type } = nodeListContext || queryVariablesContext;
  let options = propsOptions || _.get(schemaType.fields[path], 'options');
  const fullPath = `${rootPath}${rootPath && '.'}${path}`;

  if (!options) {
    console.error(`ToggleButtonOptionsFilter cannot find options for ${type}.${path}.`);
    options = [];
  }

  const handleChange = (event, value) => {
    if (!value) value = [];
    if (!value.length) value = undefined;

    if (value) {
      updateVariables({ path: `${fullPath}._in`, value });
    } else {
      updateVariables({ path: `${fullPath}._in`, value: undefined });
    }
  };

  const clear = (event) => {
    handleChange([]);
  };

  const classes = useStyles();
  const valueIds = _.get(variables, `${fullPath}._in`, []);

  return (
    <div className={classNames(classes.root, className)}>
      {label && (
        <Typography variant="body1" color="textSecondary">
          {label}
        &nbsp;&nbsp;
        </Typography>
      )}
      <ToggleButtonGroup value={valueIds} onChange={handleChange} size="small">
        {options.map((option) => {
          if (!option) return null;
          const buttonLabel = option.primary || option.label;
          if (!buttonLabel) return null;
          return (
            <ToggleButton value={option.id || option.value} key={option.id || option.value}>
              {buttonLabel}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
};

ToggleButtonOptionsFilter.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  path: PropTypes.string.isRequired,
  rootPath: PropTypes.oneOf(['where', 'args.where']),
};

ToggleButtonOptionsFilter.defaultProps = {
  label: 'Is one of these:',
  className: '',
  rootPath: 'where',
};

export { ToggleButtonOptionsFilter };
