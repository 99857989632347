import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    breakAfter: 'page',
    breakBefore: 'page',
  },
}));

const PageBreak = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} />
  );
};

PageBreak.propTypes = {
};
PageBreak.defaultProps = {
};

export { PageBreak };
