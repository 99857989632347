import React, { useState, useCallback, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import KiskaCrudForm from 'src/kiska/components/MutationContext/CrudForm';
import SaveButton from 'src/kiska/components/MutationContext/SaveButton';
import DeleteButton from 'src/kiska/components/MutationContext/DeleteButton';
import MutationSnackbars from 'src/kiska/components/MutationContext/MutationSnackbars';
import { useNode } from 'src/kiska/hooks/useNode';
import { useLog } from 'src/kiska/components/contexts/LogContext';
import { Dialog } from 'src/kiska/components/Dialog';
import { useLocationControl } from 'src/kiska/utils/useLocationControl';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';

const useStyles = makeStyles((theme) => ({
  dialog: {

  },
  content: {
    padding: theme.spacing(0, 0, 0, 0),
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: theme.spacing(0),
    padding: theme.spacing(2, 3, 2, 3),
    backgroundColor: theme.palette.background.default,
    boxShadow: `0 -2px 20px -5px hsl(0deg 0% 0% / 33%)`,
  },
}));

const CrudForm = (props) => {
  const log = useLog();
  const { title: propsTitle, richTitle: propsRichTitle, node: propsNode, onNodeChange, embeddedMode, updateFunction, scroll: propsScroll, open: propsOpen, closeOnSuccess, fullScreen: propsFullScreen, allowDelete, onOpen, liveValidation, initialValues, width, fixtures, children, onClose, onSuccess, id: propsId, trigger: propsTrigger, where, type, crudFormProps, renderRichTitle, renderTitle, maxWidth, variant, ...rest } = props;
  const { dialogId, openDialog, closeDialog, open } = useLocationControl({ open: propsOpen, onOpen, onClose });
  const classes = useStyles(props);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const id = propsId;
  const { node: fetchedNode } = useNode({ type, id, where, skip: !id && !where });

  const node = embeddedMode ? propsNode : fetchedNode;

  const scroll = propsScroll === undefined ? (sm ? 'body' : 'paper') : propsScroll;

  const handleTriggerClick = useCallback((event) => {
    event.stopPropagation();
    openDialog();
  }, [openDialog]);

  const trigger = propsTrigger ? cloneElement(propsTrigger, { onClick: handleTriggerClick }) : null;

  const handleSuccess = useCallback((...args) => {
    const [mutation, result] = args;
    const { saveAndStay } = mutation.meta;

    if (!onNodeChange && !(id || where) && (!closeOnSuccess || saveAndStay)) {
      console.error(`<CrudForm> for type "${type}" appears to be uncontrolled yet is not closing after a successfull insert. We think bad things will happen.`);
    }
    if (closeOnSuccess && !saveAndStay) closeDialog();
    if (onSuccess) onSuccess(...args);
  }, [closeDialog, closeOnSuccess, id, onNodeChange, onSuccess, type, where]);

  let content;
  let title;
  let richTitle;

  if ((id || where) && (!node || (!where && node.id !== id))) {
    // we were asked to fetch an existing node but it hasn't arrived yet
    title = 'Loading...';
    richTitle = title;
    content = (
      <DialogContent className={classes.content}>
        <Typography variant="body2" color="textSecondary" align="center">Loading data...</Typography>
      </DialogContent>
    );
  } else {
    // We either have the node we were supposed to fetch or we don't need one because this is an insert or embedded mode
    title = propsTitle !== undefined ? propsTitle : renderTitle(node);
    richTitle = propsRichTitle !== undefined ? propsRichTitle : (renderRichTitle ? renderRichTitle(node) : title);
    content = (
      <KiskaCrudForm type={type} embeddedMode={embeddedMode} onNodeChange={onNodeChange} liveValidation={liveValidation} node={node} fixtures={fixtures} initialValues={initialValues} updateFunction={updateFunction} {...rest} onSuccess={handleSuccess}>
        <DialogContent className={classes.content} style={{ width }}>
          <form>
            {children}
          </form>
        </DialogContent>
        <DialogActions className={classes.actions}>
          {allowDelete ? <DeleteButton /> : <div />}
          <div style={{ width: 0, height: 0 }} />
          <SaveButton />
        </DialogActions>
        <MutationSnackbars />
      </KiskaCrudForm>
    );
  }

  return (
    <>
      {trigger}
      <Dialog title={richTitle} dialogId={dialogId} dialogProps={{ scroll }} open={open} closeDialog={closeDialog} className={classes.dialog} maxWidth={maxWidth} fullScreen={propsFullScreen || fullScreen}>
        {content}
      </Dialog>
    </>
  );
};

CrudForm.propTypes = {
  crudFormProps: PropTypes.object,
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  where: PropTypes.object,
  children: PropTypes.node.isRequired,
  renderRichTitle: PropTypes.func,
  renderTitle: PropTypes.func,
  richTitle: PropTypes.string,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  variant: PropTypes.oneOf(['page', 'dialog']),
  trigger: PropTypes.node,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  open: PropTypes.bool,
  fixtures: PropTypes.object,
  initialValues: PropTypes.object,
  width: PropTypes.number,
  fullScreen: PropTypes.bool,
  closeOnSuccess: PropTypes.bool,
  allowDelete: PropTypes.bool,
  scroll: PropTypes.string,
};

CrudForm.defaultProps = {
  crudFormProps: null,
  id: undefined,
  where: undefined,
  renderRichTitle: undefined,
  maxWidth: 'md',
  variant: 'dialog',
  trigger: undefined,
  onClose: undefined,
  open: undefined,
  onSuccess: undefined,
  fixtures: undefined,
  initialValues: undefined,
  width: undefined,
  fullScreen: false,
  closeOnSuccess: true,
  allowDelete: true,
  richTitle: undefined,
  title: undefined,
  renderTitle: undefined,
  scroll: undefined,
};

CrudForm.displayName = 'SlickCrudForm';

export default CrudForm;
