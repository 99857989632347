import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import { MutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import SelectField from 'src/kiska/components/MutationContext/FormFields/SelectField';
import DatePicker from 'src/kiska/components/MutationContext/FormFields/DatePicker';
import DateTimePicker from 'src/kiska/components/MutationContext/FormFields/DateTimePicker';
import BooleanField from 'src/kiska/components/MutationContext/FormFields/BooleanField';
import { GridLineBreak } from 'src/components/forms';
import { NodeField } from 'src/kiska/components/MutationContext/FormFields/NodeField';
import { useMutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import { subMilliseconds, differenceInMilliseconds, addMilliseconds } from 'date-fns';
import _ from 'lodash';
import { Hideable } from 'src/kiska/components/Hideable';
import { LocalNodeProvider } from 'src/kiska/components/LocalNodeContext';
import CrudForm from '../../updates/CrudForm';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 4, 2),
  },
}));

const EventUpdateFormInner = (props) => {
  const classes = useStyles(props);
  const { defaultDuration } = props;
  const { onChange, getFieldValue, getAllFieldValues } = useMutationContext();
  const node = getAllFieldValues({ jsonFieldsToObjects: true, includeNode: true });
  // const { user } = useUser();

  const changeHours = (start, end) => {
    onChange('hours', (differenceInMilliseconds(end, start) / 60 / 60 / 1000).toFixed(2));
  };

  const handleDateChange = (name, value) => {
    const end = getFieldValue('end');
    const start = getFieldValue('start');
    if (name === 'start' && value > end) {
      const newEnd = addMilliseconds(value, defaultDuration * 60 * 60 * 1000);
      onChange('end', newEnd);
      changeHours(start, newEnd);
    } else if (name === 'end' && value < start) {
      const newStart = subMilliseconds(value, defaultDuration * 60 * 60 * 1000);
      onChange('start', newStart);
      changeHours(newStart, value);
    } else {
      changeHours(start, end);
    }
  };

  const handleHoursChange = (name, value) => {
    const start = getFieldValue('start');
    const newEnd = addMilliseconds(start, value * 60 * 60 * 1000);
    onChange('end', newEnd);
  };

  useEffect(() => {
    const hours = getFieldValue('hours');

    if (hours === undefined || hours === null || hours === '') {
      const end = getFieldValue('end');
      const start = getFieldValue('start');
      changeHours(start, end);
    }
  });

  return (
    <LocalNodeProvider node={node} type="form">
      <div className={classes.root}>
        <Grid container spacing={4} alignItems="center">

          <Grid item xs={12} md={6}>
            <SelectField name="type" label="Event Type" filterOptions={(option) => option.value !== 'stat-holiday'} />
          </Grid>
          <GridLineBreak />

          <Hideable dep="type" showIfOneOf={['job-work', 'worker-not-available']}>
            <Grid item xs={12} md={6}>
              <NodeField name="user" label="Worker" />
            </Grid>
          </Hideable>
          <Hideable dep="type" showIfOneOf={['job-work']}>
            <Grid item xs={12} md={6}>
              <NodeField name="job" label="Job" />
            </Grid>
          </Hideable>

          <Hideable dep="type" showIfOneOf={['stat-holiday', 'other', 'worker-not-available']}>
            <Grid item xs={12} md={6}>
              <TextField name="title" label="Title" />
            </Grid>
            <Grid item xs={12}>
              <TextField name="description" label="Description" rows={3} />
            </Grid>
          </Hideable>

          <Grid item>
            <DateTimePicker name="start" label="Start At" pickerProps={{ openTo: 'hours' }} onChange={handleDateChange} />
          </Grid>
          <Grid item>
            <BooleanField name="allDay" label="All Day" />
          </Grid>
          <GridLineBreak />
          {/* <Grid item style={{ padding: 8 }}>
          <Typography variant="body1" color="textSecondary">OR</Typography>
        </Grid> */}
          <Grid item>
            <TextField name="hours" label="Hours" realNumber onChange={handleHoursChange} blurMode={false} selectAllOnFocus style={{ maxWidth: 150 }} />
          </Grid>
          <Grid item style={{ padding: 8 }}>
            <Typography variant="body1" color="textSecondary">OR</Typography>
          </Grid>
          <Grid item>
            <DateTimePicker name="end" label="Finish At" pickerProps={{ openTo: 'hours' }} onChange={handleDateChange} />
          </Grid>
          <GridLineBreak />

        </Grid>
      </div>
    </LocalNodeProvider>
  );
};

EventUpdateFormInner.propTypes = {
  variant: PropTypes.string,
};
EventUpdateFormInner.defaultProps = {
  variant: undefined,
};

const EventUpdateForm = ({ id, trigger, updateFunction, defaultDuration, onClose, onSuccess, open, variant, type, fixtures: propsFixtures, initialValues: propsInitialValues }) => {
  const renderTitle = (n) => (n ? `Edit Event` : 'Create Event');

  const fixtures = {
    ...propsFixtures,
  };

  const initialValues = {
    ...propsInitialValues,
  };

  const schemaExtensions = {
    types: {
      event: {
        fields: {
          hours: {
            type: 'String',
            clientOnly: true,
            neverDirties: true,
          },
        },
      },
    },
  };

  // if (type === 'job-work') {
  //   _.set(schemaExtensions, 'types.event.fields.job.validation.required', true);
  //   _.set(schemaExtensions, 'types.event.fields.user.validation.required', true);
  // }

  return (
    <CrudForm
      type="event"
      id={id}
      trigger={trigger}
      onClose={onClose}
      open={open}
      renderTitle={renderTitle}
      maxWidth="md"
      allowDelete
      fixtures={fixtures}
      initialValues={initialValues}
      schemaExtensions={schemaExtensions}
      onSuccess={onSuccess}
      updateFunction={updateFunction}
    >
      <EventUpdateFormInner
        variant={variant}
        type={type}
        defaultDuration={defaultDuration}
      />
    </CrudForm>
  );
};

EventUpdateForm.propTypes = {
  id: PropTypes.string,
  trigger: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.string,
  defaultDuration: PropTypes.number,
};
EventUpdateForm.defaultProps = {
  id: undefined,
  trigger: undefined,
  onClose: undefined,
  open: undefined,
  variant: '',
  defaultDuration: 2,
};

export { EventUpdateForm };
