/* eslint-disable react/prop-types */
import React from 'react';

export default {
  types: {
    role: {
      renderers: {
        inline: ({ node }) => <>{node.label}</>,
      },

      selections: {
        default: 'id label',
      },

      fields: {
        label: {
          type: 'String',
        },
      },
    },

    user: {
      renderers: {
        inline: ({ node }) => <>{node.displayName}</>,
      },

      selections: {
        default: `
          id displayName email firstName lastName phone
          avatar {id url mimeType name }
          role { id label }
          workEntries(where: {end: {_is_null: true}}){ 
            id start startTime end endTime notes
            job {id shortId title specs}
          }
          options preferences
          updatedAt createdAt
          payRates {
            id pay_rate { 
              id label price cost tasks(order_by: [{task: {label: asc}}]) { 
                id task { id label description }
              }
            }
          }
          resources
        `,
      },

      fields: {
        displayName: {
          type: 'String',
          label: 'Display Name',
          validation: {
            required: true,
          },
        },

        email: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        phone: {
          type: 'String',
          validation: {
            required: false,
          },
        },

        firstName: {
          type: 'String',
          validation: {
            required: false,
          },
        },

        lastName: {
          type: 'String',
          validation: {
            required: false,
          },
        },

        avatar: {
          type: 'file',
          validation: {
          },
        },

        role: {
          type: 'role',
          validation: { required: true },
        },

        options: {
          type: 'Json',
        },
        'options.allowPasswordlessLogin': {
          type: 'Boolean',
        },

        payRates: {
          type: 'pay_rate',
          hasMany: true,
          validation: { required: true },
        },

        preferences: {
          type: 'Json',
        },

        resources: { type: 'resource', hasMany: true, defaultValue: [] },

        'preferences.themeName': { type: 'String' },
        'preferences.soundEfxOnSwitchJobs': { type: 'Boolean' },
        'preferences.soundEfxOnStopWork': { type: 'Boolean' },
        'preferences.calendarColor': { type: 'String', label: 'Calendar Color' },
        'preferences.title': { type: 'String' },

      },
    },
  },
};
