import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SimpleTable } from './SimpleTable';
import { PayRateUpdateForm } from './PayRateUpdateForm';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  tasks: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& > li': {
      marginBottom: theme.spacing(0.5),
    },
    '& > li:last-child': {
      marginBottom: 0,
    },
  },
}));

const PayRatesPanel = () => {
  const classes = useStyles();
  const initial = { orderBy: [{ label: 'asc' }] };

  const columns = [
    { label: 'Name', fieldName: 'label' },
    { label: 'Price', fieldName: 'price', Renderer: ({ value }) => <>${value}/hr</>, orderByPath: 'price' },
    { label: 'Cost', fieldName: 'cost', Renderer: ({ value }) => <>${value}/hr</>, orderByPath: 'cost' },
    {
      label: 'Tasks',
      fieldName: 'tasks',
      orderByPath: null,
      Renderer: ({ value }) => (
        <ul className={classes.tasks}>
          {value && value.map(({ task: { id, label } }) => (
            <li key={id}>
              <Chip label={label} />
            </li>
          ))}
        </ul>
      ),
    },
  ];

  const tableProps = {
    hideFilterButtons: true,
    hideOrderByButtons: false,
    hideHeader: false,
    hidePageControls: true,
  };

  return (
    <>
      <SimpleTable
        type="pay_rate"
        initial={initial}
        addLabel="Add Pay Rate"
        UpdateForm={PayRateUpdateForm}
        columns={columns}
        tableProps={tableProps}
        rowClickAction="edit"
      />
    </>
  );
};

PayRatesPanel.propTypes = {
};
PayRatesPanel.defaultProps = {
};

export { PayRatesPanel };
