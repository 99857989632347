import React from 'react';
import { AsbestosForm } from './AsbestosForm';
import { PaintingForm } from './PaintingForm';

const forms = [
  {
    name: 'asbestos',
    title: 'Asbestos',
    hideableProps: {
      dep: 'type',
      showIfEq: 'asbestos',
    },
    form: <AsbestosForm />,
  },
  {
    name: 'painting',
    title: 'Painting',
    hideableProps: {
      dep: 'type',
      showIfEq: 'painting',
    },
    form: <PaintingForm />,
  },
];

export { forms };
