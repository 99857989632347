import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, InputAdornment, Link } from '@material-ui/core';
import { Phone as PhoneIcon, MailOutline as EmailIcon } from '@material-ui/icons';
import { replaceNewLinesWithBreaks } from 'src/kiska/utils';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1.0em',
      display: 'inline-block',
      marginRight: theme.spacing(0.5),
    },
  },
  notes: {
    fontStyle: 'italic',
  },
}));

const ContactRenderer = (props) => {
  const classes = useStyles(props);
  const { contact, showTitle, showNotes, showAddress, showCountry, titleProps, addressProps, notesProps } = props;

  if (!contact) return null;

  const { address1, address2, city, state, postalCode, company, country, name, phone, email, notes } = contact;

  return (
    <>
      {showTitle && <Typography variant="h6" align="left" className={classes.title} {...titleProps}>{company || name}</Typography>}
      {showAddress && (
        <Typography variant="body2" className={classes.address} {...addressProps}>
          {name && <>Contact: {name}<br /></>}
          {phone && <><Link href={`tel:${phone}`} className={classes.link}><PhoneIcon />{phone}</Link><br /></>}
          {email && <><Link href={`mailto:${email}`} className={classes.link}><EmailIcon />{email}</Link><br /></>}
          {address1 && <>{address1}<br /></>}
          {address2 && <>{address2}<br /></>}
          {city && `${city}, `}{state && `${state}, `}{postalCode}<br />
          {showCountry && country && <>{country}</>}
        </Typography>
      )}
      {showNotes && (
        <Typography variant="body2" color="textSecondary" className={classes.notes} {...notesProps}>{replaceNewLinesWithBreaks(notes)}</Typography>
      )}
    </>
  );
};

ContactRenderer.propTypes = {
  contact: PropTypes.object,
  showTitle: PropTypes.bool,
  showNotes: PropTypes.bool,
  showAddress: PropTypes.bool,
  showCountry: PropTypes.bool,
  titleProps: PropTypes.object,
  addressProps: PropTypes.object,
  notesProps: PropTypes.object,
};
ContactRenderer.defaultProps = {
  contact: undefined,
  showTitle: true,
  showNotes: true,
  showAddress: true,
  showCountry: false,
  titleProps: undefined,
  addressProps: undefined,
  notesProps: undefined,
};

export { ContactRenderer };
