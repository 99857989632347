import React from 'react';
// import PropTypes from 'prop-types';
// import { Typography, Button, ButtonBase } from '@material-ui/core';
// import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  Settings as SettingsIcon,
  Storage as ServerIcon,
  AttachMoney as PayIcon,
} from '@material-ui/icons';
import SettingsIcon2 from 'mdi-material-ui/Tune';
import { TabPanel, ContentTabs } from 'src/kiska/components/ContentTabs';
import DataIcon from 'mdi-material-ui/PlaylistEdit';
import { AdminTab } from './AdminTab';
import { PayRatesTab } from './PayRatesTab';
import { AppSettingsTab } from './AppSettingsTab';
import { DataTab } from './DataTab';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}));

const SettingsDashboard = () => {
  const classes = useStyles();

  const tabs = [
    {
      role: 'admin',
      value: 'admin',
      label: 'Admin',
      icon: <ServerIcon />,
    },
    {
      roles: ['manager', 'office-admin'],
      value: 'app-settings',
      label: 'Business',
      icon: <SettingsIcon2 />,
    },
    {
      roles: ['manager', 'office-admin'],
      value: 'data',
      label: 'Data',
      icon: <DataIcon />,
    },
    {
      roles: ['manager', 'office-admin'],
      value: 'pay-rates',
      label: 'Pay Rates',
      icon: <PayIcon />,
    },
  ];

  return (
    <div className={classes.root}>
      <ContentTabs
        urlPathIndex={2}
        title="Settings"
        titleAdornment={<SettingsIcon size={40} />}
        defaultTabIndex={0}
        tabs={tabs}
      >
        <TabPanel value="admin">
          <AdminTab />
        </TabPanel>
        <TabPanel value="app-settings">
          <AppSettingsTab />
        </TabPanel>
        <TabPanel value="data">
          <DataTab />
        </TabPanel>
        <TabPanel value="pay-rates">
          <PayRatesTab />
        </TabPanel>
      </ContentTabs>
    </div>
  );
};

SettingsDashboard.propTypes = {
};
SettingsDashboard.defaultProps = {
};

export { SettingsDashboard };
