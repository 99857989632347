import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FilterFilledIcon from 'mdi-material-ui/Filter';
import FilterOutlinedIcon from 'mdi-material-ui/FilterOutline';
import ClearFiltersIcon from 'mdi-material-ui/FilterRemoveOutline';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Button, Typography, IconButton, Table as MuiTable, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Paper } from '@material-ui/core';
import classNames from 'classnames';
import Color from 'color';

import { NodeListContext } from '.';
import PageControls from './PageControls';
import FilterDialog from './FilterDialog';
import { withSchema } from '../contexts/SchemaContext';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    position: 'relative',
    overflowX: 'auto',

    '& tr:last-child': {
      '& > td': {
        borderBottom: 'none',
      },
    },
  },
  longText: {
    maxWidth: 300,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  loadingScreen: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    backgroundColor: 'rgba(255,255,255,.7)',
  },
  bodyRow: {
    height: 'auto',
  },
  headRow: {
    height: 'auto',
  },
  focusedRow: {
    backgroundColor: Color(theme.palette.secondary.main).fade(0.6).string(),
  },
  bodyCell: {
    padding: theme.spacing(0.5, 1),
    fontSize: '1.2em',
    verticalAlign: 'center',
    fontWeight: 400,
    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  headCell: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    fontSize: '1em',
    verticalAlign: 'bottom',
    color: theme.palette.text.secondary,
  },
  filterButton: {
    margin: `-5px -5px`,
    padding: `0`,
    '& svg': {
      fontSize: '.65em',
      color: 'rgba(255,255,255,.33)',
    },
  },
  nonSortingHeadCellContent: {
    paddingRight: theme.spacing(1),
  },
});

class Table extends Component {
  static contextType = NodeListContext

  static propTypes = {
    firstOrderByDirection: PropTypes.string,
    height: PropTypes.number,
    className: PropTypes.string,
    onRowClick: PropTypes.func,
    columns: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    classes: PropTypes.object.isRequired,
    hideFilterButtons: PropTypes.bool,
    hideOrderByButtons: PropTypes.bool,
    hideHeader: PropTypes.bool,
    hidePageControls: PropTypes.bool,
    focusedRowIndex: PropTypes.number,
    type: PropTypes.string,
    nodes: PropTypes.array,
  }

  static defaultProps = {
    firstOrderByDirection: 'asc_nulls_last',
    height: undefined,
    className: '',
    onRowClick: null,
    hideFilterButtons: false,
    hideOrderByButtons: false,
    hideHeader: false,
    hidePageControls: false,
    focusedRowIndex: null,
    type: undefined,
    nodes: undefined,
  }

  static fieldOrderByDirection = (path, orderBy) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const criteria of orderBy) {
      const dir = _.get(criteria, path);
      if (dir) return dir;
    }
    return null;
  }

  static buildDefaultColumn = (fieldName, schemaType) => {
    const schemaField = schemaType.fields[fieldName];
    if (!schemaField) {
      return {};
    }

    return {
      fieldName,
      label: schemaField.label,
      Renderer: schemaField.renderers.inline,
      filters: schemaField.filters,
      orderByPath: fieldName,
      className: undefined,
    };
  }

  constructor(props, context) {
    super(props, context);

    this.contextMode = !!context;
    this.type = context ? context.type : props.type;
    this.schemaType = context ? context.schemaType : props.schema.types[props.type];
  }

  buildColumns = () => {
    const { columns: propsColumns } = this.props;
    let columns;

    if (typeof propsColumns === 'string') {
      columns = propsColumns.split(/[\s,]+/);
    } else {
      columns = propsColumns;
    }
    columns = columns.map((column) => {
      if (typeof column === 'string') {
        return Table.buildDefaultColumn(column, this.schemaType);
      }
      const newColumn = _.merge({}, Table.buildDefaultColumn(column.fieldName, this.schemaType), column);
      return newColumn;
    });

    return columns;
  }

  renderRow = (node, index) => {
    const { classes, focusedRowIndex } = this.props;

    return (
      <TableRow hover key={node.id} className={classNames(classes.bodyRow, focusedRowIndex === index ? classes.focusedRow : '')} onClick={this.handleRowClick(node)}>
        {this.renderCells(node)}
      </TableRow>
    );
  }

  renderCells = (node) => {
    const { classes, onRowClick } = this.props;

    const cells = this.columns.map(({ handleBlanks, fieldName, Renderer, className }) => {
      let content;

      const value = _.get(node, fieldName);

      if (handleBlanks && (value === undefined || value === null || value === '')) {
        content = '';
      } else {
        content = <Renderer value={value} node={value} parentNode={node} />;
      }

      return (
        <TableCell
          key={fieldName}
          align="left"
          className={classNames(classes.bodyCell, className)}
          style={{ cursor: onRowClick ? 'pointer' : 'initial' }}
        >
          {content}
        </TableCell>
      );
    });

    return cells;
  }

  toggleOrderByDirection = (direction) => {
    const { firstOrderByDirection } = this.props;

    switch (direction) {
      case 'asc': return 'desc';
      case 'desc': return 'asc';
      case 'asc_nulls_first': return 'desc_nulls_last';
      case 'desc_nulls_first': return 'asc_nulls_last';
      case 'asc_nulls_last': return 'desc_nulls_first';
      case 'desc_nulls_last': return 'asc_nulls_first';
      case null:
      default:
        return firstOrderByDirection;
    }
  }

  handleOrderByField = (path, direction) => () => {
    const { updateVariables } = this.context;

    updateVariables(({ orderBy }) => {
      const currentDirection = Table.fieldOrderByDirection(path, orderBy);
      const newDirection = direction || this.toggleOrderByDirection(currentDirection);

      return {
        replaceInArray: {
          rootPath: 'orderBy',
          pathValuePairs: [{ path, value: newDirection }],
        },
      };
    });
  }

  filterClauseIsEmpty = (clause) => {
    if (typeof clause === 'object') {
      return _.every(clause, this.filterClauseIsEmpty);
    }

    if (clause === undefined) return true;
    if (clause === '') return true;
    return false;
  }

  fieldHasActiveFilters = (field) => {
    const { variables: { where } } = this.context;

    if (!this.filterClauseIsEmpty(_.get(where, field))) return true;

    if (where._and) {
      for (let i = 0; i < where._and.length; i++) {
        if (!this.filterClauseIsEmpty(where._and[i][field])) return true;
      }
    }
    if (where._or) {
      for (let i = 0; i < where._or.length; i++) {
        if (!this.filterClauseIsEmpty(where._or[i][field])) return true;
      }
    }

    return false;
  }

  renderHeadCells = () => {
    let orderBy;
    if (this.contextMode) {
      orderBy = this.context.variables.orderBy;
    }
    const { classes, hideFilterButtons, hideOrderByButtons } = this.props;

    const cells = this.columns.map(({ fieldName, label, filters, orderByPath }) => {
      let fieldHasActiveFilters = false;
      let orderByDirection = null;
      let isSortable = false;
      let isFilterable = false;

      if (this.contextMode) {
        fieldHasActiveFilters = this.fieldHasActiveFilters(fieldName);
        orderByDirection = Table.fieldOrderByDirection(orderByPath, orderBy);
        isSortable = !!orderByPath;
        isFilterable = true;
      }
      const content = label;

      return (
        <TableCell className={classes.headCell} key={fieldName} align="left">
          {isSortable && !hideOrderByButtons
            ? (
              <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                <TableSortLabel
                  active={!!orderByDirection}
                  direction={_.startsWith(orderByDirection, 'asc') ? 'asc' : 'desc'}
                  onClick={this.handleOrderByField(orderByPath)}
                >
                  {content}
                </TableSortLabel>
              </Tooltip>
            )
            : <span className={classes.nonSortingHeadCellContent}>{content}</span>}

          {!hideFilterButtons && isFilterable && filters && filters.length ? (
            <FilterDialog
              field={fieldName}
              filters={filters}
              trigger={(
                <Tooltip title={`Fitler by ${label}`} placement="bottom-start" enterDelay={300}>
                  <IconButton className={classes.filterButton} color={fieldHasActiveFilters ? 'secondary' : undefined}>
                    {fieldHasActiveFilters ? <FilterFilledIcon /> : <FilterOutlinedIcon />}
                  </IconButton>
                </Tooltip>
              )}
            />
          ) : null}
        </TableCell>
      );
    });

    return cells;
  }

  renderLoader = (loading) => {
    const { classes } = this.props;

    return (
      <div className={classes.loadingScreen}>
        <CircularProgress size={50} thickness={1} color="primary" />
      </div>
    );
  }

  clearFilters = () => {
    const { updateVariables } = this.context;
    updateVariables({ path: 'where', value: {} });
  }

  renderEmpty = () => {
    const { columns } = this.props;
    return (
      <TableRow>
        <TableCell colSpan={columns.length} style={{ padding: `0px 16px` }}>
          <Typography variant="body1" align="center">
            No {this.schemaType.pluralLabel} found.<br />
            Try adjusting your filters or search criteria.<br />
            {this.contextMode && (
              <Button variant="contained" color="secondary" onClick={this.clearFilters} style={{ marginTop: 8 }}>
                <ClearFiltersIcon /><span>Clear All Filters</span>
              </Button>
            )}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  handleRowClick = (node) => () => {
    const { onRowClick } = this.props;
    if (onRowClick) onRowClick(node);
  }

  render() {
    let loading;
    let nodes;
    if (this.contextMode) {
      loading = this.context.queryResult.loading;
      nodes = this.context.nodes;
    } else {
      loading = false;
      nodes = this.props.nodes;
    }
    const { height, classes, className, hideHeader, hidePageControls } = this.props;
    this.columns = this.buildColumns();

    return (
      <div className={classNames(className, classes.root)}>
        <div className={classes.tableWrapper} style={{ maxHeight: height }}>
          {(loading && (!nodes || !nodes.length)) && this.renderLoader()}
          <MuiTable className={classes.table} stickyHeader size="small">
            {!hideHeader && (
              <TableHead className={classes.head}>
                <TableRow className={classes.headRow}>
                  {this.renderHeadCells()}
                </TableRow>
              </TableHead>
            )}
            <TableBody className={classes.body}>
              {(!nodes || !nodes.length) ? this.renderEmpty() : null}
              {(nodes && nodes.length) ? nodes.map((node, index) => this.renderRow(node, index)) : null}
            </TableBody>
          </MuiTable>
        </div>
        {/* {!hidePageControls && nodes && !!nodes.length && <PageControls />} */}
      </div>
    );
  }
}

Table = _.flow(
  withSchema,
  withStyles(styles),
)(Table);
export default Table;
