import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    '@media only screen': {
      backgroundColor: '#FF0',
      display: 'inline-block',
      padding: `0px 3px`,
      margin: `-1px -3px`,
      border: 'solid 1px #FFF',

      '&.empty': {
        backgroundColor: '#F00',
        minWidth: '10em',
        height: '1.2em',
      },
    },
  },
}));

const StaticReportValue = (props) => {
  const { value, children, v, ...rest } = props;
  const classes = useStyles(props);
  const content = v || value || children;

  let empty = false;
  if (content === '' || content === null || content === undefined) {
    empty = true;
  }

  return (
    <span className={classNames(classes.root, empty && 'empty')} {...rest}>{content}</span>
  );
};

StaticReportValue.propTypes = {
};
StaticReportValue.defaultProps = {
};

export { StaticReportValue };
