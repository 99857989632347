import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Table, Link, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StaticReportHeader } from './StaticReportHeader';
import { StaticReportLabelValue } from './StaticReportLabelValue';
import { StaticReportValue as Value } from './StaticReportValue';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  table: {
    breakInside: 'avoid',
  },
}));

const AsbestosRiskAssessmentReport = (props) => {
  const { vars } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <StaticReportHeader />
      <Typography variant="h4" align="center" color="textSecondary">ASBESTOS RISK ASSESSMENT FOR:</Typography>
      <Typography variant="h4" align="center" gutterBottom>{vars.asbestosMaterial}</Typography>
      <br />

      <StaticReportLabelValue label="Project Location" value={vars.projectLocation} />
      <StaticReportLabelValue label="Date of Assessment" value={vars.assessmentDate} />

      <Typography variant="body1">
        <Typography variant="h6" component="span">SITE DESCRIPTION: </Typography>
        This work involves the removal and disposal
        of <Value v={vars.asbestosMaterial} /> at <Value v={vars.projectLocation} /> from <Value v={vars.removalArea} /> by Assured
        Asbestos Abatement (AAA) was hired by <Value v={vars.hiredBy} /> to conduct a risk assessment at the address above.
        This risk assessment is based upon a known hazard and proposed work to be completed.
        This assessment was developed for the current material conditions and the work to be done.
        It reflects only those procedures proposed in completing the assigned tasks.
        Other non-hygiene related occupational Health and Safety requirements are not addressed in this Risk assessment.
        The known hazards are:
      </Typography>
      <br />
      <Typography variant="h6">1. Potential Asbestos Exposure</Typography>
      <br />
      <Typography variant="body1">
        Due to the potential for the generation of airborne asbestos fibers during the removal, this work is classified
        as <strong><Value v={vars.asbestosRisk} /> Work</strong>.
      </Typography>
      <br />
      <Typography variant="body1">
        <Typography variant="h6" component="span">SCOPE: </Typography>
        The removal of the <Value v={vars.asbestosMaterial} /> from <Value v={vars.removalArea} /> will consist of
        setting up <Value v={vars.removalProcedure} /> shall be constructed in such a way that it creates a seamless seal to the
        contained removal area.  The workspace must be under negative pressure provided by a
        certified HEPA Negative air machine that is DOP tested.
        <br /><br />
        There were samples taken by <Value v={vars.samplesTakenBy} /> and they came back with
        positive asbestos containing materials as shown in the chart below.
      </Typography>
      <br />
      <Typography variant="body1">
        The risk assessment is limited to the materials, conditions and areas described in the following table:
      </Typography>
      <br />
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Material</TableCell>
            <TableCell>Sample Results</TableCell>
            <TableCell>Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><Value v={vars.asbestosMaterial} /></TableCell>
            <TableCell><Value v={vars.sampleResults} /></TableCell>
            <TableCell><Value v={vars.asbestosMaterial} /> from <Value v={vars.removalArea} /></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />
      <Typography variant="h6" component="span">Assessed Risk for <Value v={vars.asbestosMaterial} />: </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>Condition of this material: <Value v={vars.materialCondition} /></li>
          <li>Friability: <Value v={vars.friability} /></li>
          <li>Percent of asbestos: <Value v={vars.sampleResults} /></li>
          <li>Exposure risk to abatement workers: <Value v={vars.asbestosRisk} /></li>
          <li>Exposure risk to persons outside the contained work area: <Value v={vars.riskToPersonsOutside} /></li>
        </ul>
      </Typography>
      <br />
      <Typography variant="h4" component="span">Overall Hazard <Value v={vars.asbestosRisk} /></Typography>
      <br />
      <br />
      <Typography variant="body1">
        <Typography variant="h6" component="span">AIR MONITORING: </Typography>
        Air monitoring is mandatory for high risk work, and should be conducted by a third party company, a
        clearance air sample is mandatory to be taken to ensure the quality of completed work. Air monitoring is
        required to be completed as follows:
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>
            One air sample must be collected per shift within the clean room of the worker decontamination facility
          </li>
          <li>
            One air sample must be collected per shift from outside of the work area if there are any
            unprotected workers are in the vicinity of the containment
          </li>
          <li>
            One occupational air sample must be collected per shift from within the containment to ensure
            respiratory protection is adequate and the ALARA philosophy is being maintained.
          </li>
          <li>
            Air clearance air sampling (minimum two samples) must be conducted within the containment upon
            completion of the removal activities and before tear down of the containment system.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1">
        The above air monitoring must be conducted in accordance with WorkSafeBC regulations and
        results must be made available to the workers within 24 hours of sample collection.
      </Typography>
      <br />
      <Typography variant="body1">
        <Typography variant="h6" component="span">CLOSURE: </Typography>
        Prior to the start of removal, Site specific work procedures must be developed for this project and be
        employed to remove the asbestos containing materials in a safe manner.  The procedures must be submitted
        to the Occupational Hygiene section of WorkSafeBC, along with a Notice of project (NOP), 48 hours prior to
        the start of asbestos removal.  The site specific work procedures and NOP, along with the Hazardous Materials
        Survey, the employee Fit Tests and the sample analysis report must be readily available on site during the removal work.
        <br /><br />
        All workers must be fully knowledgeable and adhere to the work procedures outlined.  Entry into the work area
        must be controlled and only authorized personnel shall be allowed within the work area.
      </Typography>
      <br />
      <Typography variant="body1">
        <Typography variant="h6" component="span">REPORT USE AND LIMITATIONS: </Typography>
        In preparations AAA (Assured Asbestos Abatement Ltd) examined and conducted an onsite visual
        of this home, and has provided the information in a professional manner, using
        that level of skill and care normally exercised for similar projects under similar
        conditions by reputable and competent consultants and in accordance with our normal
        terms and conditions.  AAA shall not be held responsible for conditions or consequences
        arising from relevant facts that were concealed, withheld or not fully disclosed at
        the time the report was prepared.
        <br /><br />
        Should you have any questions or require further information please do not hesitate to contact us at your convenience.
      </Typography>
      <br />
      <Typography variant="body1">
        Assured Asbestos Abatement Ltd.<br />
        Amy Byles<br />
        604-208-2054<br />
        AHEARA Building Inspector #<Value v={vars.ahearaBuildingInspector} /><br />
        <Link href="https://www.assuredasbestos.ca/">www.assuredasbestos.ca</Link>
      </Typography>
    </div>
  );
};

// AsbestosRiskAssessmentReport.propTypes = {
// };
// AsbestosRiskAssessmentReport.defaultProps = {
// };

export { AsbestosRiskAssessmentReport };
