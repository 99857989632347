import _ from 'lodash';

const forms = [
  require('./DailySafetyForm').default,
  require('./ChangeOrderForm').default,
].reduce((acc, form) => ({
  ...acc,
  [`${form.name}_v${form.version}`]: {
    ...form,
    id: `${form.name}_v${form.version}`,
  },
}), {});

export { forms };
