import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StaticReportValue } from './StaticReportValue';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
  },
  label: {
    textTransform: 'uppercase',
    marginRight: theme.spacing(1),
  },
  value: {

  },
}));

const StaticReportLabelValue = (props) => {
  const { label, value, v, children, noValueWrapper } = props;
  const classes = useStyles(props);

  let content = '';
  if (noValueWrapper) content = value || children;
  else content = <StaticReportValue>{v || value || children}</StaticReportValue>;

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.label}>{label}:</Typography>
      <Typography variant="body1" className={classes.value}>{content}</Typography>
    </div>
  );
};

StaticReportLabelValue.propTypes = {
};
StaticReportLabelValue.defaultProps = {
};

export { StaticReportLabelValue };
