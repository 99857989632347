import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';
import { TextField, FileField, DateField, NodeField, SelectField } from 'src/kiska/components/ViewOrUpdateFields';
import _ from 'lodash';
import { useLocalNode } from 'src/kiska/components/LocalNodeContext';
import { Grid } from 'src/kiska/components/Grid';
import { FormSection } from 'src/components/forms';

import { Hideable } from 'src/kiska/components/Hideable';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  content: {
    padding: theme.spacing(0, 0, 0, 0),
  },
}));

const stdWidths = { xs: 12, sm: 12, md: 6, lg: 3 };

const PaintingForm = forwardRef((props, forwardedRef) => {
  // const classes = useStyles(props);
  // const { node } = useLocalNode();

  return (
    <Hideable>

      <FormSection title="Painting">
        <Grid item {...stdWidths}>
          <SelectField name="specs.painting.location" label="Location" />
        </Grid>
        <Grid item {...stdWidths}>
          <TextField
            name="specs.painting.area"
            label="Area"
            InputProps={{
              endAdornment: <InputAdornment position="end">sq ft</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item {...stdWidths}>
          <SelectField name="specs.painting.workingAtHeights" label="Working at Heights?" />
        </Grid>
      </FormSection>

    </Hideable>
  );
});

PaintingForm.propTypes = {
};
PaintingForm.defaultProps = {
};
PaintingForm.displayName = 'PaintingForm';

export { PaintingForm };
