import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from 'src/kiska/components/MutationContext/FormFields/TextField';
import { FileField } from 'src/kiska/components/MutationContext/FormFields/FileField';
import { NodeField } from 'src/kiska/components/MutationContext/FormFields/NodeField';
import BooleanField from 'src/kiska/components/MutationContext/FormFields/BooleanField';
import SelectField from 'src/kiska/components/MutationContext/FormFields/SelectField';
import DateField from 'src/kiska/components/MutationContext/FormFields/DatePicker';
import KiskaCrudForm from 'src/kiska/components/MutationContext/CrudForm';
import MutationSnackbars from 'src/kiska/components/MutationContext/MutationSnackbars';
import { FormSection, GridLineBreak } from 'src/components/forms';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import { PayPeriodPicker } from 'src/kiska/components/NodeList/PayPeriodPicker';
import { useSchema } from 'src/kiska/components/contexts/SchemaContext';
import { useNodes } from 'src/kiska/hooks/useNode';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2),
  },
}));

const payPeriodTypeOptions = [
  { value: 'day-count', label: 'Fixed Number of Days' },
];

const AppSettingsTab = (props) => {
  const classes = useStyles(props);
  const initial = { orderBy: [{ label: 'asc' }] };
  const { appSettings } = useAppSettings();
  const { schema } = useSchema();
  // const { nodes: users } = useNodes({ type: 'user' });
  const { nodes: roles } = useNodes({ type: 'role' });

  if (!roles.length) return null;

  const handlePayPeriodPickerChange = (value) => {
    console.log(value);
  };

  const boolWidths = { xs: 12, sm: 12, md: 6, lg: 4 };

  return (
    <div className={classes.root}>
      <KiskaCrudForm type="app_setting" liveValidation node={appSettings} onChangeMutation="update" onChangeMutationWait={2000}>

        <FormSection title="Identity" initiallyExpanded={false} justify="center">
          <Grid item xs={6}>
            <FileField name="data.logo" />
          </Grid>
          <Grid item xs={6}>
            <FileField name="data.blackAndWhiteLogo" />
          </Grid>
          <Grid item xs={12}>
            <TextField name="data.companyName" />
          </Grid>
          <Grid item xs={12}>
            <TextField name="data.tagLine" rows={5} />
          </Grid>
        </FormSection>

        <FormSection title="Jobs" initiallyExpanded={false}>
          <Grid item {...boolWidths}>
            <BooleanField name="data.jobs.useScheduling" />
          </Grid>
          <Grid item {...boolWidths}>
            <BooleanField name="data.jobs.useAssignments" />
          </Grid>
          <Grid item {...boolWidths}>
            <BooleanField name="data.jobs.useChangeOrders" />
          </Grid>
          <Grid item {...boolWidths}>
            <BooleanField name="data.jobs.useDailySafetyRecords" />
          </Grid>
          <Grid item {...boolWidths}>
            <BooleanField name="data.jobs.allJobsAllTasks" />
          </Grid>
          <Grid item {...boolWidths}>
            <BooleanField name="data.jobs.enableJobsBoard" />
          </Grid>
          <Grid item {...boolWidths}>
            <BooleanField name="data.jobs.useResources" />
          </Grid>
          <GridLineBreak />
          <Grid item xs={12} md={6}>
            <SelectField
              name="data.jobs.workableStatuses"
              label="Workable Statuses"
              helperText="Any jobs with one of these status will be availble to workers to start working on"
              options={schema.types.job.fields.status.options}
              multiple
            />
          </Grid>
        </FormSection>

        <FormSection title="Calendar" initiallyExpanded={false} />

        <FormSection title="Work Log" initiallyExpanded={false}>
          <Grid item {...boolWidths}>
            <SelectField
              name="data.workLog.editingRoles"
              multiple
              options={roles.map((role) => ({ value: role.id, primary: role.label }))}
            />
          </Grid>
        </FormSection>

        <FormSection title="Reports" initiallyExpanded={false}>
          <Grid item xs={12} md={6}>
            <TextField name="data.reports.watermarkText" />
          </Grid>
          <GridLineBreak />

          <Grid item xs={12} md={6}>
            <TextField name="data.reports.footerLine1" label="Footer First Line" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField name="data.reports.footerLine2" label="Footer Second Line" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField name="data.reports.footerLine3" label="Footer Third Line" />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField name="data.reports.footerLine4" label="Footer Fouth Line" />
          </Grid>
        </FormSection>

        <FormSection title="Pay Periods" initiallyExpanded={false}>
          <Grid item xs={12} md={6} lg={4}>
            <SelectField name="data.payPeriods.type" label="Type of Pay Period" options={payPeriodTypeOptions} />
          </Grid>
          <Grid item>
            <TextField name="data.payPeriods.count" integer label="Number of Days" />
          </Grid>
          <Grid item>
            <DateField name="data.payPeriods.start" label="Beginning of Any Period" />
          </Grid>

          <Grid item xs={12} container justify="center">
            <PayPeriodPicker open onChange={handlePayPeriodPickerChange} staticMode />
          </Grid>
        </FormSection>

        <MutationSnackbars successMessage={false} />
      </KiskaCrudForm>
    </div>
  );
};

AppSettingsTab.propTypes = {
};
AppSettingsTab.defaultProps = {
};

export { AppSettingsTab };
