import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Table, Link, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StaticReportHeader } from './StaticReportHeader';
import { StaticReportLabelValue } from './StaticReportLabelValue';
import { StaticReportValue as Value } from './StaticReportValue';
import { PageBreak } from './PageBreak';
import { ImageValue } from './ImageValue';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  table: {
    breakInside: 'avoid',
  },
  imageInList: {
    margin: theme.spacing(2, 0),
  },
}));

const LimitedHazardousMaterialsSurvey = (props) => {
  const { vars } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <StaticReportHeader />
      <Typography variant="h3" align="center" color="textSecondary">
        LIMITED HAZARDOUS MATERIALS SURVEY REPORT
      </Typography>
      <Typography variant="h4" align="center" gutterBottom>{vars.asbestosMaterial}</Typography>
      <br />

      <StaticReportLabelValue label="Project Location" value={vars.projectLocation} />
      <StaticReportLabelValue label="Date of Survey" value={vars.assessmentDate} />
      <br />
      <ImageValue file={vars.coverImage} maxWidth="100%" maxHeight="5in" alt="Cover Image" />
      <br />
      <PageBreak />

      <Typography variant="h5" component="span">INRODUCTIONS:</Typography>
      <Typography variant="body1">
        Assured Asbestos Abatement Ltd. (AAA) was hired by <Value v={vars.hiredBy} /> to complete asbestos
        abatement in <Value v={vars.removalArea} />
        at <Value v={vars.projectLocation} />.  This survey was conducted for due diligence and regulatory
        compliance purposes as required by Section 20.112 of the BC Occupational Health and Safety Regulations (WorkSafeBC, (WCB)).
        This is a limited Hazardous Materials survey as the only material being removed that has asbestos content in
        it is <Value v={vars.asbestosMaterial} /> in <Value v={vars.removalArea} />.
        <br /><br />
        Samples were taken by <Value v={vars.samplesTakenBy} /> for sample analysis.  The findings were positive, finding
        the <Value v={vars.asbestosMaterial} /> to be  <Value v={vars.sampleResults} /> Asbestos containing.
        WCB defines Asbestos as a Hazardous building Material.
        <br /><br />
        This HMS was conducted by <Value v={vars.assessmentBy} /> on <Value v={vars.assessmentDate} />.
        The objective of the HMS is to identify specified hazardous building materials that are
        being removed for <Value v={vars.hiredBy} /> <Value v={vars.reasonForSurvey} />.
        This was determined by visual assessment, selective sampling and laboratory analysis.  Specific methodology employed
        during the HMS is included in Appendix 1.  The regulatory framework pertaining to hazardous materials in included in Appendix 2.
        The terms of reference for this report are included in Appendix 5. This is a limited hazardous materials
        survey limited to the VSF in the Kitchen only.
      </Typography>
      <br />

      <Typography variant="h5" component="span">LIMITATIONS:</Typography>
      <Typography variant="body1">
        This HMS was limited to the <Value v={vars.asbestosMaterial} /> that will be removed in <Value v={vars.removalArea} />.
        The analytical results of visually inspecting the renovation area provided the assessment of this HMS.
        No hidden cavities, water drainage or plumbing systems were included in the scope of this HMS.
        It is not possible to comprehensively evaluate all hidden spaces such as behind wall surfaces.
        If during the course of renovation work hidden suspect asbestos materials are identified there
        should not be disturbed until further evaluation can be made by a QP.
        <br /><br />
        Materials assumed not to contain asbestos during this HMS included wood, wood composite materials,
        carpet, synthetic plastics, metals, glass and concrete.
      </Typography>
      <br />
      <PageBreak />

      <Typography variant="h5" component="span">ASSESSMENT SUMMARY:</Typography>
      <Typography variant="body1">
        This Report is limited to the <Value v={vars.asbestosMaterial} /> samples that were sent away
        by <Value v={vars.samplesTakenBy} /> for the purpose of <Value v={vars.reasonForSurvey} />
        at <Value v={vars.projectLocation} />. This information is based upon the visual inspection by myself, as
        the qualified person, and from the sample results provided to me,
        and is being represented to the best of my knowledge. The <Value v={vars.asbestosMaterial} /> is
        located in <Value v={vars.removalArea} />. The analysis report for the samples is as follows:
      </Typography>
      <br />

      <Typography variant="h6" component="span">A. Lab Results:</Typography>
      <Typography variant="body1">
        The lab use was <Value v={vars.labName} />.
      </Typography>
      <br />
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Sample ID<br />(<Value v={vars.labName} />)</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Material</TableCell>
            <TableCell>Estimated Asbestos %</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><Value v={vars.sampleId} /></TableCell>
            <TableCell><Value v={vars.removalArea} /></TableCell>
            <TableCell><Value v={vars.asbestosMaterial} /></TableCell>
            <TableCell><Value v={vars.sampleResults} /></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />

      <Typography variant="body1">
        This LIMITED Hazardous Materials Survey Report was conducted for due diligence and regulatory
        compliance purposes as required by Section 20.112 of the BC Occupational Health and Safety Regulations (WorkSafeBC).
        <br /><br />
        WorkSafeBC defines Hazardous Materials as:
      </Typography>
      <ul>
        <li>Asbestos containing material</li>
        <li>Lead or any other heavy metal</li>
        <li>Toxic, flammable or explosive material</li>
        <li>Ozone depleting substances (ODS</li>
        <li>Crystalline Silica</li>
        <li>Mould growth</li>
        <li>Radioactive materials</li>
        <li>Radon</li>
      </ul>
      <br />
      <PageBreak />

      <Typography variant="h5" component="span">FINDINGS:</Typography>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Hazardous Materials</TableCell>
            <TableCell>Type / Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Asbestos containing Building Materials (ACM)</TableCell>
            <TableCell><Value v={vars.asbestosMaterial} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Lead in Paints</TableCell>
            <TableCell>Lead was not tested for</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Lead Products</TableCell>
            <TableCell>Lead products were not observed</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Mercury</TableCell>
            <TableCell>Mercury products were not observed</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Polychlorinated Biphenyls (PCBs)</TableCell>
            <TableCell>PCB products were not observed</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Crystalline Silica</TableCell>
            <TableCell>Crystalline Silica products were not observed</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Ozone Depleting Substances</TableCell>
            <TableCell>Ozone depleting substances were not observed</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Mould</TableCell>
            <TableCell>No moisture issues were located no were there any visible signs of mould</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Flammable and Explosive Materials</TableCell>
            <TableCell>Flammable and explosive materials were not observed</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <br />

      <Typography variant="h5" component="span">RECOMMENDATIONS:</Typography>
      <ul>
        <li>
          All asbestos containing materials must be removed using safe work procedures and practices
          prior to any other construction activities
        </li>
        <li>
          An asbestos risk assessment must be performed by a qualified professional prior to any
          other construction activities
        </li>
        <li>
          If a suspect hazardous material not identified in this report is discovered during the course
          of construction activities, this material must not be disturbed until a qualified person has
          collected a sample(if required) and determined whether the material is hazardous
        </li>
        <li>
          A copy of this report must be posted on site along with the Notice of Project, which must be
          submitted 48 hours prior to the removal of any asbestos containing materials to WorkSafeBc.
          Other documents that must be on site are: the site specific work procedures, risk assessment,
          sample analysis report, worker fit test, and equipment DOP certification
        </li>
      </ul>
      <Typography variant="body1">
        Due to the findings of the sample analysis report, the asbestos content
        of the <Value v={vars.asbestosMaterial} /> is <Value v={vars.sampleResults} />.
        This work will be considered <Value v={vars.asbestosRisk} /> work
        and <Value v={vars.riskComplianceMeasures} />.
        are required to be compliant.  There is reported that no other work will be taking place that will
        involve the removal of any other suspect building materials in the business.
      </Typography>
      <br />
      <PageBreak />

      <Typography variant="h5" component="span">APPENDIX 1 - METHODOLOGY</Typography>
      <br /><br />
      <Typography variant="h6" component="span">ASBESTOS CONTAINING MATERIALS (ACM)</Typography>
      <Typography variant="body1">
        An initial walk-through of the business was conducted and observations were made of the walls,
        ceiling and floors to make a determination if asbestos could be present.  To confirm of discount
        the presence of asbestos, reprehensive bulk samples were collected and the sample location being
        given a unique sample number.  The number of representative bulk samples collected was consistent
        with recognized industry standards and principles of good occupational hygiene practices.
        The approximate quantity, location and sample locations of suspect ACMs were recorded.
        <br /><br />
        Bulk samples were submitted for analysis in accordance with PLM: Bulk Asbestos Building Materials
        EPA 600 R 93/116.1993.  Chain –of-Custody protocol was observed during the handling and transportation
        of the bulk samples. The asbestos analysis was completed using a stop positive approach.  Stop positive
        means samples in a homogenous material sample set were analyzed consecutively and when a sample was
        identified as asbestos containing, further sample analysis within that sample set was not completed.
        <br /><br />
        Samples containing >0.5% asbestos are identified as being asbestos containing.  If Vermiculite insulation
        is found it is identified as being asbestos containing if any trace of asbestos was found.
      </Typography>
      <br />

      <Typography variant="h5" component="span">APPENDIX 2 – REGULATORY FRAMEWORK</Typography>
      <ol>
        <li>BC Occupational Health and Safety Regulations</li>
        <li>Safe work Practices for Handling Asbestos, WorkSafeBC, 2017</li>
        <li>Hazardous Waste Regulations, BC Ministry of Environment</li>
        <li>Transportation of Dangerous Goods, Regulations SOR/2008-34, Transportation of Dangerous Goods Act</li>
        <li>Ozone Depleting Substances and other Halocarbons Regulation, BC Reg 220/2006 Environmental Management Act</li>
      </ol>
      <br />
      <PageBreak />

      <Typography variant="h5" component="span">APPENDIX 3 – ANALYTICAL RESULTS</Typography>
      {vars.analyticalResultsFiles.map((file) => <ImageValue key={file.id} className={classes.imageInList} file={file} maxWidth="100%" maxHeight="8in" />)}
      <br />
      <PageBreak />

      <Typography variant="h5" component="span">APPENDIX 4 – TERMS OF REFERENCE</Typography>
      <ul>
        <li>This report has been prepared in accordance with generally accepted consulting practices and the level of care for hazardous materials and occupational health and safety consulting services.  No other warranty, expressed or implied, is made.</li>
        <li>This report should be read in conjunction with all other communication between AAA and the client with respect to the specific site</li>
        <li>This report has been prepared in with the specific site layout as set out by the contractor when AAA services were retained to carry out this project</li>
        <li>This report has been prepared for the sole use of the client and no other party may rely on this report or any components of this report</li>
        <li>This report remains the copyright of Assured Asbestos Abatement Ltd.</li>
        <li>AAA accepts no responsibility for any damages to a third party resulting from the use of this report</li>
        <li>This report is based on the conditions observed at the date of the assessment and is limited specifically to the areas defined in the report</li>
        <li>AAA has relied on any information provided by the client regarding the subject site and has assumed this information is accurate and truthful. This report in written or digital format must not be altered in any way by the client</li>
      </ul>
      <br />

      <Typography variant="body1" color="textSecondary">DISCLAIMER OF LIABILITY</Typography>
      <Typography variant="body2" color="textSecondary">
        This HMS has been performed to the best of my ability and with the necessary level of resources available to me.
        AAA will not be liable for any loss, damage, expenses or claims, including, without limitation, economic and consequential
        losses, or direct or indirect incidental, exemplary or punitive damages whether in contract, or otherwise arising
        directly or indirectly from or in connection with the performance of the work, or from the subsequent discovery or
        elimination of any and all consequences or pre-existing conditions.  Under no circumstances will AAA be responsible
        for any damage or injury that may arise at any time after completion of work, including but not limited to mould or
        fungus and any conditions that may arise from or in connection with the presence of mould or fungus.
      </Typography>
      <br />

      <Typography variant="body1" color="textSecondary">
        Thank you for using our services.
        <br /><br />
        Assured Asbestos Abatement Ltd.<br />
        Amy Byles – Owner/Operator<br />
        604-208-2054<br />
        AHEARA Building Inspector #<Value v={vars.ahearaBuildingInspector} /><br />
        <a href="https://www.assuredasbestos.ca">www.assuredasbestos.ca</a><br />
      </Typography>

    </div>
  );
};

// LimitedHazardousMaterialsSurvey.propTypes = {
// };
// LimitedHazardousMaterialsSurvey.defaultProps = {
// };

export { LimitedHazardousMaterialsSurvey };
