import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { format, isSameDay, differenceInCalendarDays, differenceInMilliseconds } from 'date-fns';
import Color from 'color';
import { Draggable } from 'react-beautiful-dnd';
import NoUserIcon from 'mdi-material-ui/Cancel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    userSelect: 'none',
    padding: `0px 4px 0px 4px`,
    margin: `2px 0px 2px 0px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      minHeight: 40,
    },
    '& p, div': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '&.singleDay': {
      marginLeft: 4,
      marginRight: 4,
      borderRadius: 4,
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '&.isDraggable': {
      cursor: 'move',
    },
  },
  top: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  job: {
    '& p': {
      lineHeight: 1.2,
      paddingBottom: 2,
    },
  },
  user: {
    display: 'flex',
    alignItems: 'center',
  },
  noUserIcon: {
    fontSize: '1em',
    marginRight: '.5em',
  },
}));

const Event = (props) => {
  const classes = useStyles(props);
  const { className, event, onClick, isDraggable: propsIsDraggable, hover: propsHover, isFirstDayInRow, onMouseEnter, onMouseLeave, index, date } = props;
  const theme = useTheme();
  const [stateHover, setStateHover] = useState(false);

  const hover = propsHover || stateHover;

  let time = '';
  if (!event.isMultiDay && !event.allDay) time = `${format(event.start, `h:mm`)}-${format(event.end, `h:mmaaaaa'm`)}`;

  const title = event.title;

  const handleClick = (e) => {
    if (event.type === 'stat-holiday') return;
    if (onClick) {
      e.stopPropagation();
      e.preventDefault();
      onClick(event);
    }
  };

  const handleMouseEnter = () => {
    setStateHover(true);
    if (onMouseEnter) onMouseEnter(event);
  };
  const handleMouseLeave = () => {
    setStateHover(false);
    if (onMouseLeave) onMouseLeave();
  };

  // All this color stuff should probably be moved to styles
  let backgroundColor;
  let color = '#FFF';
  if (event.type === 'job-work') {
    backgroundColor = event.user.preferences.calendarColor || '#888';
    if (!hover) backgroundColor = Color(backgroundColor).fade(0.3).string();
  } else if (event.type === 'stat-holiday') {
    if (theme.palette.type === 'dark') {
      backgroundColor = 'hsla(0, 0%, 0%, 0%)';
      color = 'hsla(0, 100%, 70%, 100%)';
    } else {
      color = 'hsla(0, 100%, 30%, 100%)';
      color = theme.palette.text.secondary;
    }
  } else if (event.type === 'worker-not-available') {
    backgroundColor = event.user.preferences.calendarColor || '#888';
    if (!hover) backgroundColor = Color(backgroundColor).fade(0.3).string();
    backgroundColor = `repeating-linear-gradient(
      45deg,
      ${backgroundColor},
      ${backgroundColor} 20px,
      ${Color(backgroundColor).darken(0.2).string()} 20px,
      ${Color(backgroundColor).darken(0.2).string()} 40px
    )`;
  } else {
    if (theme.palette.type === 'dark') {
      if (hover)backgroundColor = 'hsla(0, 0%, 5%, 100%)';
      else backgroundColor = 'hsla(0, 0%, 10%, 100%)';
      color = '#FFF';
    } else {
      if (hover)backgroundColor = 'hsla(0, 0%, 80%, 100%)';
      else backgroundColor = 'hsla(0, 0%, 90%, 100%)';
      color = theme.palette.text.primary;
    }
  }

  const isDraggable = propsIsDraggable && !event.isMultiDay && (event.type !== 'stat-holiday');

  let showText = true;
  if (event.isMultiDay) {
    if (isSameDay(event.start, date)) showText = true;
    else if (isFirstDayInRow) showText = true;
    else showText = false;
  }

  let tooltip;
  let tooltipDates;

  if (event.isMultiDay) {
    tooltipDates = `${format(event.start, `MMM d`)} - ${format(event.end, `MMM d`)} (${differenceInCalendarDays(event.end, event.start) + 1} days)`;
  } else if (event.allDay) {
    tooltipDates = `All Day`;
  } else if (!event.isMultiDay) {
    tooltipDates = `${format(event.start, `h:mmaaaaa'm`)} - ${format(event.end, `h:mmaaaaa'm`)} (${(differenceInMilliseconds(event.end, event.start) / 60 / 60 / 1000).toFixed(2)} hours)`;
  }

  if (event.type === 'job-work') {
    tooltip = (
      <>
        Worker: {event.user.displayName}<br />
        Job: {event.job.shortId} - {event.job.title}<br />
        Customer: {event.job.customer.company || event.job.customer.name}
      </>
    );
  } else if (event.type === 'worker-not-available') {
    tooltip = (
      <>
        {event.user.displayName} not available
        {event.title && <><br />{event.title}</>}
        {event.description && <><br />{event.description}</>}
      </>
    );
  } else {
    tooltip = (
      <>
        {event.title}
        {event.description && <><br />{event.description}</>}
      </>
    );
  }

  if (tooltipDates) tooltip = <>{tooltip}<br />{tooltipDates}</>;

  const showUser = ['worker-not-available'].includes(event.type);

  const renderFunc = (provided, snapshot) => {
    const moreClasses = [
      snapshot.isDragging && 'dragging',
      isDraggable && 'isDraggable',
      event.type === 'job-work' && 'jobWork',
      event.type === 'stat-holiday' && 'statHoliday',
      !event.isMultiDay && 'singleDay',
    ];
    return (
      <div
        className={classNames(classes.root, className, ...moreClasses)}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <Tooltip title={tooltip}>
          <div
            className={classNames(classes.content, ...moreClasses)}
            style={{ background: backgroundColor, color }}
          >
            {showText && (
              <>
                <div className={classes.top}>
                  {time && <Typography variant="body2" color="inherit">{time}</Typography> }
                  {event.user && showUser && (
                    <Typography variant="body2" color="inherit" className={classes.user}>
                      {event.type === 'worker-not-available' && <NoUserIcon color="inherit" className={classes.noUserIcon} />}
                      {event.user.displayName}
                    </Typography>
                  )}
                  {title && <Typography variant="body2" color="inherit">{title}</Typography> }
                </div>
                {event.type === 'job-work' && (
                  <div className={classes.job}>
                    <Typography variant="body2" color="inherit">{event.job.title}</Typography>
                  </div>
                )}
              </>
            )}
            {!showText && <div><Typography variant="body2">&nbsp;</Typography></div>}
          </div>
        </Tooltip>
      </div>
    );
  };

  if (isDraggable) {
    return (
      <Draggable draggableId={event.id} index={index} isDragDisabled={!isDraggable}>
        {renderFunc}
      </Draggable>
    );
  }

  return renderFunc({}, {});
};

Event.propTypes = {
};
Event.defaultProps = {
};

export { Event };
