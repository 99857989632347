import React from 'react';
import {
  ExitToApp as LogoutIcon,
  VpnKey as LoginIcon,
  Menu as MenuIcon,
  People as UsersIcon,
  Build as JobIcon,
  Timer as WorkEntryIcon,
  Dashboard as DashboardIcon,
  Settings as SettingsIcon,
  Cached as SwitchJobsIcon,
  PlayArrow as PlayIcon,
} from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';
import CustomerIcon from 'mdi-material-ui/AccountTie';
import ResourceIcon from 'mdi-material-ui/Bookshelf';
import CalendarIcon from 'mdi-material-ui/Calendar';
import MyCalendarIcon from 'mdi-material-ui/CalendarAccount';
import WorkerIcon from 'mdi-material-ui/AccountHardHat';
import UserIcon from 'mdi-material-ui/Account';
import JobBoardIcon from 'mdi-material-ui/NewspaperVariantMultipleOutline';
import ReportIcon from 'mdi-material-ui/FileChart';
import deepFreeze from 'deep-freeze';

const buildMenus = ({ appSettings, settings, user, auth, history }) => {
  const anonItems = [
    {
      roles: ['anon'],
      primary: 'Login',
      icon: <LoginIcon />,
      action: () => {
        auth.loginWithRedirect({
          redirectPath: '/app/',
        });
      },
    },
  ];

  const userItems = [
    {
      group: true,
      roles: ['manager', 'employee', 'admin', 'office-admin'],
      items: [
        {
          primary: () => (
            user.workEntries && user.workEntries.length ? 'Switch Jobs' : 'Start Working'
          ),
          roles: ['manager', 'admin', 'office-admin', 'employee'],
          icon: () => (user.workEntries && user.workEntries.length ? <SwitchJobsIcon style={{ color: red[500] }} /> : <PlayIcon style={{ color: green[500] }} />),
          to: '/app/job-switcher',
        },
        {
          primary: 'My Work Log',
          // secondary: 'View / Make Corrections',
          icon: <WorkEntryIcon />,
          to: () => `/app/my-work-log`,
        },
        {
          primary: 'My Calendar',
          // secondary: 'View / Make Corrections',
          icon: <MyCalendarIcon />,
          to: () => `/app/my-calendar`,
        },
        {
          primary: 'My Profile',
          icon: <UserIcon />,
          to: () => `/app/profile/${user.id}/profile`,
        },
      ],
    },
  ];

  const otherItems = [];

  if (appSettings.data.jobs.useScheduling) {
    otherItems.push({
      primary: 'Calendar',
      roles: ['manager', 'admin', 'office-admin', 'employee'],
      icon: <CalendarIcon />,
      to: '/app/calendar',
    });
  }

  otherItems.push({
    primary: 'Workers',
    roles: ['manager', 'admin', 'office-admin', 'employee'],
    icon: <WorkerIcon />,
    to: '/app/worker-board',
  });

  otherItems.push({
    primary: 'Jobs',
    roles: ['manager', 'admin', 'office-admin', 'employee'],
    icon: <JobIcon />,
    to: '/app/jobs',
  });

  if (appSettings.data.jobs.enableJobsBoard) {
    otherItems.push({
      primary: 'Job Board',
      roles: ['manager', 'admin', 'office-admin', 'employee'],
      icon: <JobBoardIcon />,
      to: '/app/job-board',
    });
  }

  otherItems.push({
    primary: 'Customers',
    roles: ['manager', 'admin', 'office-admin', 'employee'],
    icon: <CustomerIcon />,
    to: '/app/customers',
  });

  if (appSettings.data.jobs.useResources) {
    otherItems.push({
      primary: 'Resources',
      roles: ['manager', 'admin', 'office-admin', 'employee'],
      icon: <ResourceIcon />,
      to: '/app/resources',
    });
  }

  otherItems.push({
    primary: 'Reports',
    roles: ['manager', 'admin', 'office-admin'],
    icon: <ReportIcon />,
    to: '/app/reports',
  });

  const adminItems = [
    {
      group: true,
      roles: ['admin', 'manager', 'office-admin'],
      items: [
        {
          primary: 'Settings',
          icon: <SettingsIcon />,
          to: '/app/settings',
        },
      ],
    },
  ];

  const mainMenu = {
    icon: MenuIcon,
    roles: ['manager', 'employee', 'anon', 'admin', 'contractor', 'office-admin'],
  };

  if (['admin', 'manager', 'office-admin'].includes(user.role.id)) {
    mainMenu.items = [
      ...otherItems,
      { divider: true },
      ...userItems,
      { divider: true },
      ...adminItems,
    ];
  } else if (['employee', 'contractor'].includes(user.role.id)) {
    mainMenu.items = [
      ...userItems,
      { divider: true },
      ...otherItems,
    ];
  } else {
    mainMenu.items = [
      ...anonItems,
    ];
  }

  const menus = {
    main: mainMenu,
  };

  deepFreeze(menus);
  return menus;
};

export { buildMenus };
