/* eslint-disable react/prop-types */
import React from 'react';
import { fragments } from './fragments';

export default {
  types: {
    event: {
      renderers: {
        inline: ({ node }) => <>{node.user ? node.user.displayName : ''}</>,
      },

      selections: {
        default: `
          ${fragments.event.basic}
        `,
      },

      fields: {
        start: { type: 'Date', validation: { required: true } },
        end: { type: 'Date', validation: { required: true } },
        title: {
          type: 'String',
          validation: {
            requiredIf: { dep: 'type', oneOf: ['other', 'stat-holiday'] },
          },
        },
        description: { type: 'String' },
        job: {
          type: 'job',
          validation: {
            requiredIf: {
              dep: 'type',
              oneOf: ['job-work'],
            },
          },
        },
        user: {
          type: 'user',
          label: 'Worker',
          validation: { requiredIf: {
            dep: 'type',
            oneOf: ['job-work', 'worker-not-available'],
          } },
        },
        type: {
          type: 'String',
          label: 'Event Type',
          options: [
            { value: 'job-work', primary: 'Scheduled Job Work' },
            { value: 'worker-not-available', primary: 'Worker Not Available' },
            { value: 'other', primary: 'Other' },
            { value: 'stat-holiday', primary: 'Stat Holiday' },
          ],
          validation: { required: true },
        },
        allDay: { type: 'Boolean', validation: { required: false } },
        data: { type: 'Json', validation: { required: false } },
      },
    },
  },
};
