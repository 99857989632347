import React, { useMemo, forwardRef, useContext, useState } from 'react';
import { Typography, Chip, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { Grid } from 'src/kiska/components/Grid';
import CustomerIcon from 'mdi-material-ui/AccountTie';
import { usePrint } from 'src/kiska/components/PdfGenerator';
import { GridLineBreak } from 'src/components/forms';
import { FormSection } from 'src/components/forms/FormSection';
import { TextField, FileField, NodeField, BooleanField, DateField, SelectField } from 'src/kiska/components/ViewOrUpdateFields';
import { useViewOrUpdate } from 'src/kiska/components/useViewOrUpdate';
import EditButton from 'src/components/updates/EditButton';
import { SimpleTable } from 'src/components/dashboards/settings/SimpleTable';
import WorkerIcon from 'mdi-material-ui/AccountHardHat';
import ChangeOrderIcon from 'mdi-material-ui/ArrowDecision';
import { AttachFile as FilesIcon } from '@material-ui/icons';
import MedicalIcon from 'mdi-material-ui/Ambulance';
import _ from 'lodash';
import { useNodes } from 'src/kiska/hooks/useNode';
import Fixture from 'src/kiska/components/MutationContext/FormFields/Fixture';
import { useAppSettings } from 'src/kiska/components/contexts/AppSettingsContext';
import { ReportTitle } from 'src/components/dashboards/ReportTitle';
import CalendarIcon from 'mdi-material-ui/Calendar';
import SaveButton from 'src/kiska/components/MutationContext/SaveButton';
import { useUser } from 'src/kiska/components/contexts/UserContext';
import { useMutationContext } from 'src/kiska/components/MutationContext/MutationContext';
import ResourceIcon from 'mdi-material-ui/Bookshelf';
import { CustomerUpdateForm } from '../customer/CustomerUpdateForm';
import { Hideable } from '../../../kiska/components/Hideable';
import { useLocalNode } from '../../../kiska/components/LocalNodeContext';
import { generateShortId } from './utils';
import { ContactRenderer } from '../customer/ContactRenderer';
import { useChangeOrderColumns } from '../form/forms/useChangeOrdersColumns';
import { FormViewDialog } from '../form/FormViewDialog';
import { forms } from '../form/forms';
import { useSafetyRecordColumns } from '../form/forms/useSafetyRecordColumns';
import { Calendar } from '../calendar/Calendar';
import { ResourceUpdateForm } from '../resource/ResourceUpdateForm';
import { ResourceViewDialog } from '../resource/ResourceViewDialog';
import { useResourceTableColumns } from '../resource/useResourceTableColumns';
import { forms as jobTypeSpecificForms } from './forms';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0, 2, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 1, 2, 1),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 2, 2, 2),
    },
    // margin: theme.spacing(-0, -2, -2, -2),
  },
  dialogContent: {
    padding: 0,
  },
  header: {
    // marginBottom: theme.spacing(-4),
  },
  customerViewRenderer: {
    width: '100%',
  },
  listFormSectionContent: {
    padding: theme.spacing(2, 2, 2, 2),
  },
  calendarFormSectionContent: {
    padding: theme.spacing(0, 1, 2, 1),
  },
  titleUpdateWrapper: {
    padding: theme.spacing(0, 2, 2, 2),
  },
}));

const stdWidths = { xs: 12, sm: 12, md: 6, lg: 3 };

const CustomerViewRenderer = ({ node }) => {
  const { printMode } = usePrint();
  const classes = useStyles();
  const { id } = node || {};

  return (
    <div className={classes.customerViewRenderer}>
      <ContactRenderer contact={node} titleProps={{ variant: printMode ? 'body1' : 'h6' }} />
      {!printMode && (
        <Grid container justify="flex-end">
          <CustomerUpdateForm
            id={id}
            trigger={<EditButton icon={CustomerIcon} color="primary" size="small" variant="contained" style={{ marginBottom: 8 }}>Edit Customer</EditButton>}
          />
        </Grid>
      )}
    </div>
  );
};

const tasksIncludeOneOf = (tasksToFind) => {
  const tasksArray = Array.isArray(tasksToFind) ? tasksToFind : tasksToFind.split(/[\s,]+/);
  return ({ value }) => {
    if (!value) return false;
    return !!value.find((task) => tasksArray.includes(task.task ? task.task.id : task.id));
  };
};

const allTasks = {
  asbestos: { id: 'asbestos', label: 'Asbestos Abatement', __typename: 'task' },
  general: { id: 'general-labour', label: 'General', __typename: 'task' },
  office: { id: 'office-admin', label: 'Office Admin', __typename: 'task' },
  painting: { id: '7QAF6AWMG', label: 'Painting', __typename: 'task' },
  cleaning: { id: 'FJ3BGKGUJ', label: 'Cleaning', __typename: 'task' },
};

const jobTypeTasksMap = {
  asbestos: [allTasks.asbestos],
  'asbestos-sampling': [allTasks.asbestos],
  general: [allTasks.general],
  painting: [allTasks.painting],
  cleaning: [allTasks.cleaning],
  'office-admin': [allTasks.office],
};

const JobForm = forwardRef((props, ref) => {
  const { appSettings } = useAppSettings();
  const theme = useTheme();
  const classes = useStyles();
  const { view, update } = useViewOrUpdate();
  const { node } = useLocalNode();
  const job = node;
  const isNew = !node || !node.id;
  const { printMode } = usePrint();
  const initialShortId = useMemo(generateShortId, [node && node.id]);
  const { columns: changeOrderColumns } = useChangeOrderColumns({});
  const { columns: dailySafetyColumns } = useSafetyRecordColumns({});
  const { columns: resourcesColumns } = useResourceTableColumns({ showExpires: false, showCreatedAt: true, showTags: true, showUpdatedAt: true });
  const { nodes: tasks } = useNodes({ type: 'task' });
  const { user } = useUser();
  const mutationContext = useMutationContext();
  const [titleHasChanged, setTitleHasChanged] = useState(false);

  if (!tasks.length) return null;

  const basicFieldsFilled = () => {
    return job.title && job.customer;
  };

  const handleJobTypeChange = (name, value) => {
    if (!value) return;
    const { onChange } = mutationContext;
    const newTasks = jobTypeTasksMap[value];
    if (tasks) onChange('tasks', newTasks);
  };

  return (
    <div className={classes.root} ref={ref} style={{ paddingBottom: update ? '48px' : undefined }}>
      {appSettings.data.jobs.allJobsAllTasks && update && <Fixture name="tasks" value={tasks} />}

      {view && printMode && (
        <>
          <ReportTitle title={job.title} />
          <br />
        </>
      )}
      {update && (
        <div className={classes.titleUpdateWrapper}>
          <TextField name="title" label="Job Title" blurMode={false} />
        </div>
      )}

      {/* ***** Customer & Billing ****** */}
      <FormSection title="Customer" alwaysInitiallyExpanded={isNew} icon={CustomerIcon}>
        <Grid item xs={12}>
          <NodeField
            name="customer"
            label=""
            creatableUpdateForm={CustomerUpdateForm}
            creatableInputFieldMapping="company"
            viewRenderer={CustomerViewRenderer}
          />
        </Grid>
        <GridLineBreak />
        <Hideable dep="customer" showIfTruthy>
          <Grid item {...stdWidths}>
            <SelectField name="specs.billingInfoSameAsCustomer" label="Billing Info Same as Customer?" />
          </Grid>
          <GridLineBreak />
          <Hideable dep="specs.billingInfoSameAsCustomer" showIfEq="no">
            <Grid item {...stdWidths}>
              <TextField name="specs.billingInfo" label="Billing Info" rows={4} />
            </Grid>
          </Hideable>
          <GridLineBreak />
          <Grid item {...stdWidths}>
            <SelectField name="specs.jobAddressSameAsCustomer" label="Job Address Same as Customer?" />
          </Grid>
          <GridLineBreak />
          <Hideable dep="specs.jobAddressSameAsCustomer" showIfEq="no">
            <Grid item {...stdWidths}>
              <TextField name="specs.jobAddress" label="Job Address" rows={4} />
            </Grid>
          </Hideable>
          <GridLineBreak />
          <Grid item {...stdWidths}>
            <SelectField name="specs.ferryRequired" />
          </Grid>
        </Hideable>
      </FormSection>

      {/* ***** Job ****** */}
      <Hideable show={basicFieldsFilled}>
        <FormSection title="Basic Job Info" alwaysInitiallyExpanded={isNew}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <SelectField name="type" label="Job Type" onChange={handleJobTypeChange} />
          </Grid>
          {!appSettings.data.jobs.allJobsAllTasks && (
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <NodeField name="tasks" label="Tasks" initialValue={tasks} orderBy={[{ label: 'asc' }]} />
            </Grid>
          )}
          <Hideable show={basicFieldsFilled}>
            <Grid item {...stdWidths}>
              <TextField name="shortId" label="Work Order #" initialValue={initialShortId} />
            </Grid>
            <GridLineBreak />
            <Grid item {...stdWidths}>
              <SelectField name="specs.depositReceivedVia" label="Deposit By" />
            </Grid>
            <Grid item {...stdWidths}>
              <DateField name="specs.dateDepositReceived" label="Date Deposit Received" />
            </Grid>
            <Grid item {...stdWidths}>
              <SelectField name="specs.billStatus" label="Invoice Status" />
            </Grid>
          </Hideable>

        </FormSection>
      </Hideable>

      <Hideable show={basicFieldsFilled}>
        {/* Notes */}
        <FormSection title="Notes" alwaysInitiallyExpanded={isNew}>
          <Grid item xs={12} md={12} lg={6}>
            <TextField name="specs.jobDescription" label="Job Description / Problem" rows={6} />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <TextField name="specs.workDone" label="Work Done / Solution" rows={6} />
          </Grid>
        </FormSection>

        {/* Job Type Specific Forms */}
        {jobTypeSpecificForms.map(({ name, title, hideableProps, form }) => (
          <Hideable {...hideableProps} key={name}>
            {form}
          </Hideable>
        ))}

        {/** ********* Calendar ********** */}
        {appSettings.data.jobs.useScheduling && (
          <FormSection title="Calendar" alwaysInitiallyExpanded={isNew} icon={CalendarIcon}>
            {isNew
              ? (
                <Grid item xs={12}>
                  <Grid container alignItems="center" direction="column">
                    <Typography variant="body1" color="textPrimary">You can schedule workers for this job after you save it.</Typography>
                    <SaveButton saveAndStay text="Save Job" />
                    <br />
                  </Grid>
                </Grid>
              )
              : (
                <Grid item xs={12}>
                  <Calendar job={job} />
                </Grid>
              )}
          </FormSection>
        )}

        {/* Assignments */}
        {!appSettings.data.jobs.useScheduling && appSettings.data.jobs.useAssignments && (
          <Hideable hidden={printMode}>
            <FormSection title="Worker Assignments" alwaysInitiallyExpanded={isNew} icon={WorkerIcon}>
              <Grid item xs={12}>
                <NodeField name="assignedUsers" label="Workers Assigned to Job" />
              </Grid>
            </FormSection>
          </Hideable>
        )}

        <FormSection title={`Files (${isNew ? 0 : job.files.length})`} icon={FilesIcon}>
          <Grid item xs={12}>
            <FileField name="files" label="" />
          </Grid>
        </FormSection>

        {appSettings.data.jobs.useDailySafetyRecords && view && !isNew && !printMode && (
          <FormSection
            title="Daily Safety Records"
            initiallyExpanded={false}
            classes={{ content: classes.listFormSectionContent }}
            icon={MedicalIcon}
          >
            <SimpleTable
              type="form"
              columns={dailySafetyColumns}
              UpdateForm={forms.DailySafetyForm_v1.UpdateForm}
              updateFixtures={{ jobs: [{ id: job.id }] }}
              insertFixtures={{ jobs: [{ id: job.id }] }}
              fixtures={[
                { path: 'where.jobs.job.id._eq', value: job.id },
                { path: 'where.name._eq', value: 'DailySafetyForm' },
              ]}
              rowClickAction="view"
              ViewDialog={FormViewDialog}
              viewDialogProps={{}}
            />
          </FormSection>
        )}
        {appSettings.data.jobs.useChangeOrders && view && !isNew && !printMode && (
          <FormSection
            title={`Change Orders (${isNew ? 0 : job.change_orders_aggregate.aggregate.count})`}
            initiallyExpanded={false}
            classes={{ content: classes.listFormSectionContent }}
            icon={ChangeOrderIcon}
          >
            <SimpleTable
              type="form"
              columns={changeOrderColumns}
              UpdateForm={forms.ChangeOrderForm_v1.UpdateForm}
              updateFixtures={{ jobs: [{ id: job.id }] }}
              insertFixtures={{ jobs: [{ id: job.id }] }}
              fixtures={[
                { path: 'where.jobs.job.id._eq', value: job.id },
                { path: 'where.name._eq', value: 'ChangeOrderForm' },
              ]}
              rowClickAction="view"
              ViewDialog={FormViewDialog}
              viewDialogProps={{}}
            />
          </FormSection>
        )}

        {appSettings.data.jobs.useResources && (
          <FormSection
            title={`Resources (${isNew ? 0 : job.resources_aggregate.aggregate.count})`}
            icon={ResourceIcon}
            initiallyExpanded={false}
          >
            <Grid item xs={12}>
              {update
                ? (
                  <NodeField
                    name="resources"
                    label=""
                    searchFields={[]}
                    where={(searchStr) => {
                      const fixedAnd = [
                        { userId: { _is_null: true } },
                      ];
                      if (!searchStr) {
                        return {
                          _and: fixedAnd,
                        };
                      }
                      const strs = searchStr.split(/\s/);
                      const where = {
                        _and: [
                          ...fixedAnd,
                          ...strs.map((str) => ({
                            _or: [
                              { title: { _ilike: `%${str}%` } },
                              { description: { _ilike: `%${str}%` } },
                              { tags: { tag: { label: { _ilike: `%${str}%` } } } },
                            ],
                          })),
                        ],
                      };
                      return where;
                    }}
                    renderOption={(option) => {
                      return (
                        <div className={classes.resourceOption}>
                          <Typography variant="body1" color="textPrimary">{option.title}</Typography>
                          <Typography variant="body2" color="textSecondary">{option.description}</Typography>
                          <div className={classes.tags}>
                            {option.tags && option.tags.map(({ tag }) => (
                              <Chip key={tag.id} className={classes.tag} label={tag.label} />
                            ))}
                          </div>
                        </div>
                      );
                    }}
                  />
                )
                : (
                  <SimpleTable
                    type="resource"
                    addLabel="Add New Resource"
                    columns={resourcesColumns}
                    UpdateForm={ResourceUpdateForm}
                    updateFormProps={{ showExpires: false }}
                    updateFixtures={{ }}
                    insertFixtures={{ jobs: [job] }}
                    fixtures={[
                      { path: 'where.jobs.job.id._eq', value: job.id },
                    ]}
                    actionsOnBottom={false}
                    actionsOnTop={false}
                    floatingAdd
                    enableAdd={false}
                    enableEdit={false}
                    showSearchBox={false}
                    rowClickAction="view"
                    ViewDialog={ResourceViewDialog}
                    viewDialogProps={{ updateFormProps: { fixtures: { }, showExpires: false } }}
                  />
                )}
            </Grid>
          </FormSection>
        )}

      </Hideable>

      {/* </Hideable> */}
    </div>
  );
});

JobForm.displayName = 'JobForm';

export { JobForm };
