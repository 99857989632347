import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { useSettings } from 'src/kiska/components/contexts/SettingsContext';
import defaultImage from './default-image.png';

const useStyles = makeStyles((theme) => ({
  root: {
    breakInside: 'avoid-page',
    textAlign: 'center',
  },
  image: {
    border: 'solid 2px hsla(0, 0%, 80%, 1)',
    borderRadius: 8,
    // objectFit: 'contain',
  },
}));

const ImageValue = (props) => {
  const settings = useSettings();
  const classes = useStyles(props);
  const { file, alt, height, className, wrapped, width, maxHeight, maxWidth } = props;
  const url = file ? `${settings.public.kiskaOrigin}${file.url}` : defaultImage;

  const img = <img src={url} className={classes.image} style={{ width, height, maxWidth, maxHeight }} alt={alt} />;

  if (wrapped) {
    return (
      <div className={classNames(classes.root, className)}>
        {img}
      </div>
    );
  }

  return img;
};

ImageValue.propTypes = {
};
ImageValue.defaultProps = {
  file: undefined,
  height: undefined,
  maxWidth: undefined,
  maxHeight: undefined,
  width: undefined,
  wrapped: true,
};

export { ImageValue };
