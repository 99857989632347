import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import makeAnimated from 'react-select/animated';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';
import { addIdsToSelections, generatePrimaryId } from '../utils'; // eslint-disable-line import/no-extraneous-dependencies
import { SchemaContext } from './contexts/SchemaContext'; // eslint-disable-line import/no-extraneous-dependencies
import HelperText from './MutationContext/FormFields/HelperText';
import { withLog } from './contexts/LogContext';
import { withApolloClient } from './contexts/withApolloClient';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  optionContent: {
    color: theme.palette.text.primary,
  },
  formControlLabel: {
    position: 'absolute',
    transform: 'translate(10px, -16px)',
  },
  selectRoot: {
    flex: 1,
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
});

const defaultComponents = makeAnimated();

// defaultComponents.MultiValueContainer = _.flow(
//   withStyles(styles),
// )(({ innerProps, innerRef, children, classes, data }) => {
//   const { style: innerPropsStyle, ...restInnerProps } = innerProps;
//   const label = data ? data.label || data.title : '';
//   const charCount = label ? label.length : 20;
//   const style = {
//     width: `${charCount * 0.7}em`,
//     ...innerProps.style,
//   };

//   console.log('data', data);

//   return (
//     <div ref={innerRef} {...restInnerProps} style={style}>
//       hello:
//     </div>
//   );
// });

class NodeSelector extends React.Component {
  static contextType = SchemaContext;

  static propTypes = {
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    where: PropTypes.any,
    searchFields: PropTypes.array,
    selections: PropTypes.string,
    renderOption: PropTypes.func,
    isMulti: PropTypes.bool,
    helperText: PropTypes.node,
    label: PropTypes.node,
    value: PropTypes.any,
    autoWildcards: PropTypes.bool,
    orderBy: PropTypes.array,
    limit: PropTypes.number,
    client: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    creatable: PropTypes.bool,
    creatableInputFieldMapping: PropTypes.string,
    creatableFixtures: PropTypes.object,
    buildCreatableNode: PropTypes.func,
    placeholder: PropTypes.node,
    disabled: PropTypes.bool,
    creatableUpdateForm: PropTypes.elementType,
    theme: PropTypes.object.isRequired,
    filterOptions: PropTypes.func,
    isSearchable: PropTypes.bool,
    className: PropTypes.string,
  }

  static defaultProps = {
    renderOption: null,
    where: {},
    isMulti: false,
    helperText: '',
    label: '',
    value: undefined,
    selections: '',
    searchFields: undefined,
    autoWildcards: true,
    orderBy: undefined,
    limit: 25,
    creatable: false,
    creatableInputFieldMapping: undefined,
    buildCreatableNode: undefined,
    placeholder: 'Type to search...',
    disabled: false,
    creatableUpdateForm: undefined,
    filterOptions: undefined,
    isSearchable: true,
    className: undefined,
    creatableFixtures: undefined,
  }

  static buildQuery = (props, context) => {
    const { schema } = context;
    const { type } = props;
    const schemaType = schema.types[type];
    let selections = props.selections || schemaType.selections.default;
    selections = addIdsToSelections(selections);

    const query = gql`
      query GetNodeSelectorOptions($where: ${type}_bool_exp!, $orderBy: [${type}_order_by!]){
        options: ${type}(where: $where, limit: ${props.limit}, order_by: $orderBy){
          ${selections}
        }
      }
    `;

    return query;
  }

  constructor(props, context) {
    super(props, context);
    const { type } = props;
    const { schema } = context;
    this.schemaType = schema.types[type];

    this.state = {
      inputValue: '',
      creatableUpdateFormInitialValues: undefined,
    };

    this.query = NodeSelector.buildQuery(props, context);

    this.searchFields = props.searchFields;
    if (!this.searchFields) {
      this.searchFields = _.filter(this.schemaType.fields, (field) => field.type === 'String' && !field.isJsonChild).map((field) => field.name);
    }

    if (this.schemaType.fields.updatedAt) {
      this.defaultOrderBy = [{ updatedAt: 'desc_nulls_last' }];
    } else {
      this.defaultOrderBy = [{ id: 'desc_nulls_last' }];
    }
  }

  renderOptionInner = (option) => {
    if (option.__isNew__) return `Create new ${this.schemaType.label} "${option.value}"`;

    const { renderOption } = this.props;

    if (renderOption) return renderOption(option);

    const isManyToMany = option.__typename.includes('__x__');
    if (isManyToMany) option = option[this.schemaType.name];

    const Renderer = _.get(this.schemaType, 'renderers.inline');
    if (Renderer) {
      const content = <Renderer node={option} />;
      return content;
    }
    return option.title || option.name || option.label || `ID: ${option.id}` || '<no value>';
  }

  renderOption = (option) => {
    const { classes } = this.props;

    return (
      <div className={classes.optionContent}>
        {this.renderOptionInner(option)}
      </div>
    );
  }

  handleChange = (value) => {
    const { onChange } = this.props;
    onChange(value);
  }

  loadOptions = (inputValue) => new Promise((resolve, reject) => {
    const { type, where: propsWhere, client, autoWildcards, orderBy, log, filterOptions } = this.props;

    const fixedWhere = typeof propsWhere === 'function' ? propsWhere(inputValue) : propsWhere;

    if (autoWildcards) {
      inputValue = `%${inputValue}%`;
    }

    let where = {};

    if (inputValue && inputValue.length && this.searchFields.length) {
      where._or = this.searchFields.map((field) => {
        const condition = {};
        _.set(condition, `${field}._ilike`, inputValue);
        return condition;
      });
    }

    where = { ...where, ...fixedWhere };

    const variables = {
      where,
      orderBy: orderBy || this.defaultOrderBy,
    };

    client.query({ query: this.query, variables, fetchPolicy: 'network-only' })
      .then((result) => {
        let options = result.data.options.map((option) => {
          return _.omit(option, ['options']); // Fucking react-select finds an "options" child key and then thinks that's the options array.
        });
        if (filterOptions) options = filterOptions(options);
        resolve(options);
      })
      .catch((error) => {
        log.error('<NodeSelector> Fetching options', { error, variables });
        console.error(`Error fetching options for ${type}`, error);
        reject(error);
      });
  })

  getOptionValue = (option) => {
    if (option.__isNew__) return `New ${this.schemaType.label} node: "${option.value}"`;

    const isManyToMany = option.__typename.includes('__x__');

    if (isManyToMany) option = option[this.schemaType.name];
    return option.id;
  }

  noOptionsMessage = ({ inputValue }) => {
    const { placeholder } = this.props;
    if (inputValue) return `No ${this.schemaType.pluralLabel} found.`;
    return placeholder;
  }

  getDefaultOptions = () => {
    // Could return an array here.
    // Return true to trigger react-select to call loadOptions
    return true;
  }

  handleCreateError = (error) => {
    const { enqueueSnackbar, log } = this.props;

    log.error('<NodeSelector> Creating node', { error });
    console.error('There was an error creating node for NodeSelector:', error);

    enqueueSnackbar(`Sorry, there was an error creating that ${this.schemaType.label}.`, {
      variant: 'error',
    });
  }

  handleCreate = (input) => {
    const { client, type, creatableInputFieldMapping, creatableFixtures, isMulti, buildCreatableNode, log } = this.props;
    const selections = this.schemaType.selections.default;

    if (!buildCreatableNode && !creatableInputFieldMapping) {
      log.error('<NodeSelector> No buildCreatableNode or creatableInputFieldMapping', { });
      console.error(`Must have either buildCreatableNode or creatableFieldMapping`);
      return;
    }

    const object = buildCreatableNode ? buildCreatableNode(input) : {
      [`${creatableInputFieldMapping}`]: input,
      ...creatableFixtures,
    };

    if (this.props.creatableUpdateForm) {
      this.setState({ creatableUpdateFormInitialValues: object });
      return;
    }

    object.id = generatePrimaryId();

    const variables = {
      objects: [object],
    };

    const mutation = gql`
      mutation CreateSelectNode($objects: [${type}_insert_input!]!){
        createdNode: insert_${type}(objects: $objects){ 
          returning {${selections}} 
        }
      }
    `;

    client.mutate({ mutation, variables })
      .then(({ error, data }) => {
        if (error) { this.handleCreateError(error); return; }

        const { value } = this.props;
        const newNode = data.createdNode.returning[0];
        const newValue = isMulti ? [...(value || []), newNode] : newNode;
        this.handleChange(newValue);
      })
      .catch((error) => this.handleCreateError(error));
  }

  handleCreatableUpdateFormSuccess = (mutation, result, newNode) => {
    const { isMulti, value } = this.props;
    const newValue = isMulti ? [...(value || []), newNode] : newNode;
    this.handleChange(newValue);
    this.closeCreatableUpdateForm();
  }

  closeCreatableUpdateForm = () => this.setState({ creatableUpdateFormInitialValues: undefined })

  render() {
    const { theme, isMulti, label, value, errors, creatableFixtures, isSearchable, classes, helperText, className, placeholder, creatableUpdateForm, creatable, creatableInputFieldMapping, buildCreatableNode, disabled } = this.props;
    const { creatableUpdateFormInitialValues } = this.state;

    const cPlaceholder = placeholder;
    const isCreatable = (creatable || creatableUpdateForm || buildCreatableNode || creatableInputFieldMapping);
    const Component = isCreatable ? AsyncCreatableSelect : AsyncSelect;

    const themeType = theme.palette.type;
    this.asyncStyles = {
      menu: (provided) => ({
        ...provided,
        backgroundColor: theme.palette.background.paper,
        color: 'rgba(0,0,0,.8)',
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 10000,
      }),
      control: (provided) => ({
        ...provided,
        backgroundColor: theme.overrides.MuiOutlinedInput.root.backgroundColor,
        borderColor: theme.overrides.MuiOutlinedInput.notchedOutline.borderColor,
      }),
      valueContainer: (provided) => ({
        ...provided,
        fontSize: 18,
        padding: 2,
      }),
      input: (provided) => ({
        ...provided,
        // color: '#FFFFFF',
        color: theme.palette.text.primary,
      }),
      multiValue: (provided) => ({
        ...provided,
        backgroundColor: themeType === 'dark' ? 'rgba(0,0,0,.2)' : 'rgba(0,0,0,.2)',
        // color: '#A00',
        color: theme.palette.text.secondary,
      }),
      option: (provided, { isFocused, isSelected }) => ({
        ...provided,
        fontSize: 18,
        backgroundColor: isFocused
          ? (themeType === 'dark' ? 'rgba(0,0,0,.2)' : 'rgba(0,0,0,.2)')
          : theme.palette.background.paper,
        ':hover': {
          backgroundColor: themeType === 'dark' ? 'rgba(0,0,0,.2)' : 'rgba(0,0,0,.2)',
        },
        ':active': {
          backgroundColor: themeType === 'dark' ? 'rgba(0,0,0,.25)' : 'rgba(0,0,0,.25)',
        },
      }),
    };

    let finalLabel = label;
    if (label === undefined) {
      if (isMulti) finalLabel = this.schemaType.pluralLabel;
      else finalLabel = this.schemaType.label;
    }

    return (
      <FormControl error={!!errors} className={classNames(classes.root, className)} disabled={disabled}>
        {creatableUpdateForm && (
          <this.props.creatableUpdateForm
            open={!!creatableUpdateFormInitialValues}
            initialValues={creatableUpdateFormInitialValues}
            onSuccess={this.handleCreatableUpdateFormSuccess}
            onClose={this.closeCreatableUpdateForm}
            fixtures={creatableFixtures}
          />
        )}
        {finalLabel && <FormLabel classes={{ root: classes.formControlLabel }} className={classes.formControlLabel}>{finalLabel}</FormLabel>}
        <div className={classes.row}>
          <Component
            appear
            className={classes.selectRoot}
            cacheOptions={false}
            loadOptions={this.loadOptions}
            value={value}
            onChange={this.handleChange}
            components={defaultComponents}
            isMulti={isMulti}
            isClearable
            getOptionValue={this.getOptionValue}
            getOptionLabel={this.renderOption}
            placeholder={cPlaceholder}
            noOptionsMessage={this.noOptionsMessage}
            defaultOptions
            styles={this.asyncStyles}
            menuPortalTarget={typeof document !== 'undefined' ? document.body : undefined}
            onCreateOption={isCreatable ? this.handleCreate : undefined}
            isDisabled={disabled}
            isSearchable={isSearchable}
          />
        </div>
        {/* <HelperText text={helperText} errors={[]} /> */}
        <FormHelperText>
          <HelperText text={helperText} errors={errors} />
        </FormHelperText>
      </FormControl>
    );
  }
}

NodeSelector = _.flow(
  withStyles(styles, { withTheme: true }),
  withApolloClient,
  withSnackbar,
  withLog,
)(NodeSelector);

export default NodeSelector;

/*

quantity
material
paint color

*/
