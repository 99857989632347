/* eslint-disable react/prop-types */
import React from 'react';

export default {
  types: {
    contact: {
      renderers: {
        inline: ({ node: { name, company, email, phone, id } }) => <>{company || name || email || phone || id}</>,
      },

      selections: {
        default: `
          id name company address1 address2 email phone city state postalCode country notes
          tags {id tag {id label type color icon}}
          systemTags {id system_tag {id label data}}
          jobs {
            id shortId title
          }
          activeJobs: jobs_aggregate(where: {status: {_neq: "archived"}}){
            aggregate{count}
          }
          inactiveJobs: jobs_aggregate(where: {status: {_eq: "archived"}}){
            aggregate{count}
          }
        `,
      },

      fields: {

        name: { type: 'String', validation: { required: false } },
        company: { type: 'String', validation: { required: false } },
        address1: { type: 'String', validation: { required: false } },
        address2: { type: 'String', validation: { required: false } },
        email: { type: 'String', validation: { required: false, email: true } },
        phone: { type: 'String', validation: { required: true } },
        city: { type: 'String', validation: { required: false } },
        state: { type: 'String', validation: { required: false } },
        postalCode: { type: 'String', validation: { required: false } },
        country: { type: 'String', validation: { required: false } },
        notes: { type: 'String', validation: { required: false } },

        tags: {
          type: 'tag',
          hasMany: true,
          validation: { required: false },
        },

        systemTags: {
          type: 'system_tag',
          hasMany: true,
          validation: { required: false },
        },

      },
    },
  },
};
